import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import gif from "../Assets/Images/gif.gif";

export default function CasinoPage() {
  let params = useParams();
  const { token } = useSelector((store) => store.AuthReducer);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://vbet002.com/api/game-login",
          { ...params },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response)
        setData(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [params, token]);

  return (
    <>
      <main className="main">
        {error && <div>Error: {error.message}</div>}
        {!data && (
          <div className="px-5 text-center">
            <img src={gif} width={140} className="d-block mt-5 pt-5 m-auto" />
            <h6 className="fw-normal mt-2">Loading...</h6>
          </div>
        )}
        {data && (
          <iframe
            width={"100%"}
            height="700px"
            src={data?.data?.url}
            frameBorder="0"
            className="iframe-data"
          ></iframe>
        )}
      </main>
    </>
  );
}
