import liveIcon from "../../Assets/Images/Icons/live.png";
import cricketIcon from "../../Assets/Images/Icons/cricket.png";
import tennisIcon from "../../Assets/Images/Icons/tennis.png";
import footballIcon from "../../Assets/Images/Icons/football.png";
import { Link, useParams } from "react-router-dom";
import { ArrowRight, Menu, Play, Ticket, X } from "lucide-react";
import { LuClock5 } from "react-icons/lu";
import { GoDotFill } from "react-icons/go";

import $ from "jquery";
import SideNav from "../SideNav";
import BetSlip from "../BetSlip";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import queryString from "query-string";
import BetInput from "../BetInput";
import BetInputFoot from "./BetInput";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const showSidenav = () => {
  $(".sidenav-menu").toggleClass("active");
};
function GameFootDetails() {
  const [betVisible, setbetVisible] = useState({});
  const [fancy, setFancy] = useState({});
  const [active, setActive] = useState(false);

  let params = useParams();

  const [data, setData] = useState([]);
  const [mdata, setMData] = useState([]);
  const [run, setRun] = useState();
  const [size, setSize] = useState();
  const [obj2, setObj2] = useState({});
  // console.log({obj2})



  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const [isVisible1, setIsVisible1] = useState(false);

  const toggleVisibility1 = () => {
    setIsVisible1(!isVisible1);
  };


  const [isScoreVisible, setIsScoreVisible] = useState(false);


  const toggleScoreVisibility = () => {
    setIsScoreVisible(!isScoreVisible);
  };



  const [betvisible2, setbetVisible2] = useState({
    status: -1,
    visible: false,
  });
  const { token } = useSelector((store) => store.AuthReducer);

  const getDetails = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/get-cricket-detail",
        { ...params, sport_id: 1 },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getDetails1 = async () => {
    try {
      const queryParams = queryString.stringify({ match_id: params?.match_id });
      const url = `https://vbet002.com/api/v5/get-match-session?${queryParams}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setMData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!token) {
      setbetVisible(false);
      setbetVisible2({
        status: -1,
      });
    }

  }, []);



  const timestamp = data?.data?.odds?.start_date;
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

  // Convert to local time
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  // Handling AM/PM format
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const strTime = `${hours}:${minutes}:${seconds} ${ampm}`;
  const formattedDate = `${month}/${day}/${year} ${strTime}`;





  useEffect(() => {
    const intervalId = setInterval(() => {
      getDetails();
      getDetails1();
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);


  const getDateLabel = (startDate) => {
    const matchDate = new Date(startDate * 1000);
    const today = new Date();
    const tomorrow = new Date(today);

    tomorrow.setDate(today.getDate() + 1);

    if (
      matchDate.getDate() === today.getDate() &&
      matchDate.getMonth() === today.getMonth() &&
      matchDate.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    } else if (
      matchDate.getDate() === tomorrow.getDate() &&
      matchDate.getMonth() === tomorrow.getMonth() &&
      matchDate.getFullYear() === tomorrow.getFullYear()
    ) {
      return "Tomorrow";
    } else {
      return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        matchDate
      );
    }
  };





  useEffect(() => {
    const intervalId = setInterval(() => {
      getDetails();
      getDetails1();
    }, 4000);

    return () => clearInterval(intervalId);
  }, []);


  function extractTeamNames(data) {
    if (data && data.data && data.data.data && data.data.data.odds && data.data.data.odds.name) {
      const fullName = data.data.data.odds.name;
      const [team1, team2] = fullName.split(" v ");
      return { team1, team2 };
    } else {
      return { team1: "Name not found", team2: "Name not found" };
    }
  }
  const { team1, team2 } = extractTeamNames({ data });


  function extractTeamNames1(data) {
    if (data && data.data && data.data.data && data.data.data.odds && data.data.data.odds.name) {
      const fullName = data.data.data.odds.name;
      const [team1Name, team2Name] = fullName.split(" v ");
      const teamNames = [team1Name, team2Name].map(name => formatTeamName(name));
      const abbreviatedTeamNames = teamNames.map(name => name.substring(0, 2)); // Get first two letters
      return { team3: abbreviatedTeamNames[0], team4: abbreviatedTeamNames[1] };
    } else {
      return { team3: "NA", team4: "NA" };
    }
  }

  // Function to format team name
  function formatTeamName(name) {
    const parts = name.split(' ');
    if (parts.length > 1) {
      const acronym = parts.map(part => part.charAt(0)).join('').toUpperCase();
      return `${name} (${acronym})`;
    } else {
      return name;
    }
  }

  // Example component

  const { team3, team4 } = extractTeamNames1({ data });


  return (
    <>
      <main className="main inPlay text-dark bg-light h-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <button
                className="btn border-0 mb-2 text-primary p-0"
                onClick={showSidenav}
              >
                <Menu />
              </button>
            </div>
            <div className="col-md-9 pe-md-0">
              <div className="d-flex gap-2 sidenav-menu align-items-baseline">
                <SideNav />
                <div className="w-100">
                  <div className="select-bet">
                    <div className="selectbet-header">
                      <div className="col s12 m12 bet-teamname">

                        <div className="top-header-module">
                          <div className="top-row row m-0">
                            <div className="text-counter">
                              <div className="evt-title countdownHolder">
                                <div className="live-title">
                                  <div> Virtual Soccer League </div>
                                  <div className="live-match">
                                    <GoDotFill size={20} /> Live
                                  </div>
                                </div>
                              </div>

                              <div className="teams-name">
                                <div className="date-details">
                                  <span className="date-title">{data?.data?.odds?.start_date && getDateLabel(
                                    parseInt(data &&
                                      data?.data?.odds?.start_date)
                                  )}   </span>
                                  <span>
                                    <LuClock5 />
                                  </span>
                                  <span className="ps-1"> {strTime}</span>
                                </div>
                              </div>
                            </div>
                            <div className="addmarket-icon">
                              <div className="live-stream right">
                                <a href="javascript:void(0);">
                                  <img
                                    alt=""
                                    src="https://cdn.cloudd.live/theme/powerplay247_theme/powerplay247/assets/images/live.png?v=1.1"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="bottom-row">
                            <div className="event-name">
                              <div>
                                <h4 className="d-flex">
                                  <div className="width-50">
                                    <div className="left-team-title">
                                      <div className="team-bg">
                                        <span className="title"> {team3}</span>
                                      </div>
                                      <div className="team-title-full">
                                        {team1} (V)
                                      </div>
                                    </div>
                                  </div>
                                  <div className="vs-image-content">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="42.334"
                                      height="126.473"
                                      viewBox="0 0 142.334 226.473"
                                    >
                                      <g
                                        id="Group_1"
                                        data-name="Group 1"
                                        transform="translate(-770.844 -434.5)"
                                      >
                                        <path
                                          id="Path_1"
                                          data-name="Path 1"
                                          d="M731.479,686.973l26.934-72.1H736.406l-29.561-81.129h27.262l17.435,53.691,16.725-53.691h19.708L815.236,460.5h8.211L740.183,686.973Z"
                                          transform="translate(64 -26)"
                                          fill="#628af1"
                                        />
                                        <path
                                          id="Path_2"
                                          data-name="Path 2"
                                          d="M834.658,588.507l12.536-16.229s-9.149-9.4-27.09-11.489-28.4,5.27-28.4,5.27L781.4,594.24a25.212,25.212,0,0,0,10.667,12.073c7.774,4.261,13.672,4.44,18.987,5.382s13.455.673,13.3,6.645-8.439,5.539-16.886,3.6-17.806-9.135-17.806-9.135a85.64,85.64,0,0,1-5.367,8.761c-3.158,4.494-7.266,9.217-7.266,9.217s9.232,6.608,16.423,9.329,21.865,4.859,32.555,2.235,20.116-6.8,22.837-24-12.05-22.254-16.423-23.711-16.078-3.956-22.545-5.053-5.214-5.379-3.8-6.865,7.488-2.659,15.554-.326A42.842,42.842,0,0,1,834.658,588.507Z"
                                          transform="translate(64 -26)"
                                          fill="#628af1"
                                        />
                                      </g>
                                    </svg>
                                  </div>

                                  <div className="width-50">
                                    <div className="right-team-title">
                                      <div className="team-title-full">
                                        {team2} (V)
                                      </div>
                                      <div className="team-bg">
                                        <span className="title">{team4}</span>
                                      </div>
                                    </div>
                                  </div>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col s12 m12 xl7 p-0" />
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-container">
      <div className="bg-white d-md-none d-block mb-3 rounded">
        <div className="p-3 border-bottom" onClick={toggleVisibility} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 className="mb-0">Live Streaming</h6>
          {isVisible ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        {isVisible && (
          <div className="p-3">
            <iframe
              frameBorder="0"
              src={`https://5por-tt1.top/getscore.php?chid=${params.match_id}`}
              allowFullScreen
              title="Live Streaming"
               // Adjust width and height as needed
            ></iframe>
          </div>
        )}
      </div>
    </div>
    <div className="bg-white d-md-none d-block mb-3 rounded">
        <div className="p-3 border-bottom" onClick={toggleScoreVisibility} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 className="mb-0">Live Score</h6>
          {isScoreVisible ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        {isScoreVisible && (
          <div className="p-3">
            <iframe
              frameBorder="0"
              src={`https://winx777.com/score/sportRadar/?eventId=${params.match_id}`}
              allowFullScreen
              title="Live Score"
               // Adjust width and height as needed
            ></iframe>
          </div>
        )}
      </div>


          {/* <BetSlip /> */}
          <div className="bg-white d-md-none d-block mb-3 rounded">
                      <div className="p-3 border-bottom"  onClick={toggleVisibility1} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h6 className="mb-0">My Bets</h6>
                        {isVisible1 ? <FaChevronUp /> : <FaChevronDown />}
                      </div>
                      {isVisible1 && (
                        <div className="">
                            <BetSlip />
                        </div>
                      )}
                    </div>
                  <div className="collapse-bg">
                    <span className="collapse-title ">
                      {data?.data?.odds?.marketName}
                      <span className="fw-bold ms-5"> {formattedDate}</span>
                    </span>
                  </div>
                  {/* match odds starts here */}
                  <div className="market-list">
                    {/* main loop starts here */}
                    {data &&
                      data?.data?.odds?.runner_json?.map((i, index) => (
                        <>
                          <div className="market-data">
                            <div className="row">
                              <div className="col-md-7">
                                <div className="team-market gap-2 align-items-center d-flex">
                                  {/* <div className="date-time in-play">
                            {new Date(
                                        parseInt(i.start_date) * 1000
                                      ).toLocaleDateString()}
                            </div> */}
                                  <div className="team-market">
                                    <div className="game-name">
                                      {i?.selectionName} {/* Assuming i is defined and has a property selectionName */}
                                    </div>
                                    {obj2.odds  && (
                                      <div className="fw-bold fs-5">

                                        {betvisible2.status === 0 ? (
                                          <>
                                            <small className="text-danger">0</small>
                                            <ArrowRight size={12} className="mx-1 text-success" />
                                            {index === 0 && obj2 && (
                                              <small className={betvisible2.color === "lay" ? "text-danger" : "text-success"}
>
                                                {Math.floor((obj2.odds - 1) * 100) * (obj2.stack / 100) || 0}
                                              </small>
                                            )}
                                            {index === 1 && obj2 && (
                                              <small className={betvisible2.color === "lay" ? "text-success" : "text-danger"}>
                                                {obj2.stack || 0}
                                              </small>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <small className="text-danger">0</small>
                                            <ArrowRight size={12} className="mx-1 text-success" />
                                            {index === 0 && obj2 && (
                                              <small className={betvisible2.color === "lay" ? "text-success" : "text-danger"}>
                                                {obj2.stack || 0}
                                              </small>
                                            )}
                                            {index === 1 && obj2 && (
                                              <small className={betvisible2.color === "lay" ? "text-danger" : "text-success"}>
                                                {Math.floor((obj2.odds - 1) * 100) * (obj2.stack / 100) || 0}
                                              </small>
                                            )}
                                          </>
                                        )}



                                      </div>
                                      )}
                               
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 ps-md-0 pe-md-4 mt-md-0 mt-2">
                                <div className="row">
                                  <div className="col-md-6 col-12 mb-md-0 mb-2">
                                    <div className="row matchrate">
                                      {i?.ex?.availableToBack?.slice(0,1).map((j) => (
                                        <div
                                          className="col-12 px-1"
                                          onClick={() => {
                                            setbetVisible2({
                                              ...betvisible2,
                                              status: index,
                                              odds: String(j?.price),
                                              size: j?.size,
                                              is_back: String("1"),
                                              selection_id: i?.selectionId,
                                              market_id:
                                                data?.data?.odds?.market_id,
                                              color: "back",
                                              status: index,
                                            });
                                            setActive((prev) => !prev);
                                          }}
                                        >
                                          <div className="back-rate back">
                                            {j?.price}
                                            <small className="d-block">
                                              {j?.size}
                                            </small>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>

                                  <div className="col-md-6 col-12 mb-md-0 mb-2">
                                    <div className="row matchrate">
                                      {i?.ex?.availableToLay?.slice(0,1).map((j) => (
                                        <div
                                          className="col-12 px-1"
                                          onClick={() => {
                                            setbetVisible2((prevState) => ({
                                              ...betvisible2,
                                              status: index,
                                              odds: String(j?.price),
                                              size: j?.size,
                                              is_back: String("0"),
                                              selection_id: i?.selectionId,
                                              market_id:
                                                data?.data?.odds?.market_id,
                                              color: "lay",
                                              status: index,
                                            }));
                                          }}
                                        >
                                          <div className="lay-rate lay">
                                            {j?.price}
                                            <small className="d-block">
                                              {j?.size}
                                            </small>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <>
                            {token && betvisible2 ? (
                              betvisible2.status === index ? (
                                <BetInputFoot
                                  setbetVisible2={setbetVisible2}
                                  betvisible2={betvisible2}
                                  setObj2={setObj2}
                                  obj2={obj2}
                                  active={active}
                                  setActive={setActive}
                                />
                              ) : null
                            ) : null}
                          </>
                        </>
                      ))}

                    {/* main loop ends here */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-md-block d-none">
              {/* <div className="bg-white mb-2 rounded">
                <div className="p-3 border-bottom">
                  <h6 className="mb-0">Live Streaming</h6>
                </div>
                <div className="p-3">
                <iframe  frameborder="0"  src={`https://crickexpo.in/tv/?eventId=${params.match_id}`} allowfullscreen></iframe>
                </div>
               
              </div> */}

<div className="dropdown-container">
      <div className="bg-white mb-2 rounded">
        <div className="p-3 border-bottom" onClick={toggleVisibility} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 className="mb-0">Live Streaming</h6>
          {isVisible ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        {isVisible && (
          <div className="p-3">
            <iframe
              frameBorder="0"
              src={`https://5por-tt1.top/getscore.php?chid=${params.match_id}`}
              allowFullScreen
              title="Live Streaming"
               // Adjust width and height as needed
            ></iframe>
          </div>
        )}
      </div>
    </div>
    <div className="bg-white mb-2 rounded">
        <div className="p-3 border-bottom" onClick={toggleScoreVisibility} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h6 className="mb-0">Live Score</h6>
          {isScoreVisible ? <FaChevronUp /> : <FaChevronDown />}
        </div>
        {isScoreVisible && (
          <div className="p-3">
            <iframe
              frameBorder="0"
              src={`https://winx777.com/score/sportRadar/?eventId=${params.match_id}`}
              allowFullScreen
              title="Live Score"
               // Adjust width and height as needed
            ></iframe>
          </div>
        )}
      </div>
              <BetSlip />
            </div>


          </div>
        </div>
      </main>
    </>
  );
}

export default GameFootDetails;
