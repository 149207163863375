import axios from "axios";
import { ChevronLeft, Eye, EyeOff, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeServices from "../services/HomeServices";

function SignUp() {
  const navigate = useNavigate();
  const [formData2, setFormData2] = useState({
    phone_no: "",
    name: "",
    password: "",
    username: "",
    confirmpassword: "",
    email: "",
  });

  const handleSubmit2 = async (e) => {
    e.preventDefault();

    try {
      const {data:response} = await HomeServices.signUp(formData2);

      if (response?.status===409) {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success(response?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/sign-in"); // Redirect to home page
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Registration failed. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // toggle eye button for show and hide password
  const [showPassword, setShowPassword] = useState(false);
  const togglePass = () => {
    setShowPassword(!showPassword);
  };

  // toggle eye button for show and hide confirm password
  const [showPassword2, setShowPassword2] = useState(false);
  const togglePass2 = () => {
    setShowPassword2(!showPassword2);
  };

  return (
    <>
      <div className="fade offcanvas-backdrop sign show"></div>
      <div
        role="dialog"
        aria-modal="true"
        className="steps-canvas offcanvas offcanvas-end show"
        tabIndex={-1}
      >
        <div className="offcanvas-header justify-content-between">
          <div className="offcanvas-title h5">
            <span className="top-heading">Sign Up now!</span>
          </div>
          <Link className="text-white" to="/">
            <X />
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="form-steps form-steps pt-4">
            <h2 className="h2">Welcome!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <form className="">
              <div className="mb-2">
                <input
                  placeholder="Enter Full Name*"
                  name="username"
                  value={formData2.name}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      name: e.target.value,
                    })
                  }
                  type="text"
                  id="formBasicName"
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <input
                  placeholder="Enter Email*"
                  name="email"
                  value={formData2.email}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      email: e.target.value,
                    })
                  }
                  type="text"
                  id="formBasicName"
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <input
                  placeholder="Enter Username*"
                  name="username"
                  value={formData2.username}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      username: e.target.value,
                    })
                  }
                  type="text"
                  id="formBasicName"
                  className="form-control"
                />
              </div>
              <div className="mb-2">
                <input
                  placeholder="Enter Phone Number*"
                  name="phone_no"
                  value={formData2.phone_no}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      phone_no: e.target.value,
                    })
                  }
                  type="text"
                  id="formBasicName"
                  className="form-control"
                />
              </div>
              <div className="mb-2 position-relative">
                <input
                  placeholder="Password*"
                  name="password"
                  value={formData2.password}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      password: e.target.value,
                    })
                  }
                  type={showPassword ? "text" : "password"}
                  id="formBasicPassword"
                  className="form-control"
                />
                <span className="icon" onClick={togglePass}>
                  {showPassword ? (
                    <EyeOff color="#fff" strokeWidth={1} />
                  ) : (
                    <Eye color="#fff" strokeWidth={1} />
                  )}
                </span>
              </div>
              <div className="mb-2 position-relative">
                <input
                  placeholder="Confirm Password*"
                  name="confirmpassword"
                  value={formData2.confirmpassword}
                  onChange={(e) =>
                    setFormData2({
                      ...formData2,
                      confirmpassword: e.target.value,
                    })
                  }
                  type={showPassword2 ? "text" : "password"}
                  id="formBasicPassword"
                  className="form-control"
                />
                <span className="icon" onClick={togglePass2}>
                  {showPassword2 ? (
                    <EyeOff color="#fff" strokeWidth={1} />
                  ) : (
                    <Eye color="#fff" strokeWidth={1} />
                  )}
                </span>
              </div>
              <div className="d-flex LoginBtn">
                <button type="submit" onClick={handleSubmit2} className="btn btn-primary">
                  Sign Up
                </button>
              </div>
            </form>

            <div className="sign-up mt-4">
              Already a member?
              <br />
              <Link to="/sign-in">Log In here</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
