import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Pages/Home";
import Casino from "../Pages/Casino";
import SignIn from "../Pages/SignIn";
import SignUp from "../Pages/SignUp";
import Inplay from "../Pages/Inplay";
import AccountStatementPage from "../Pages/Reports/AccountStatement";
import ProfitLossPage from "../Pages/Reports/ProfitLoss";
import BetHistoryPage from "../Pages/Reports/BetHistory";
import WithdrawPage from "../Pages/Withdraw";
import DepositPage from "../Pages/Deposit";
import CasinoPage from "../Pages/Iframe";
import { Ezugi } from "../Pages/Ezugi";
import { Vsport } from "../Pages/VirtualSports";
import GameDetails from "../Pages/GameDetails";
import ChangePassword from "../Pages/ChangePassword";
import Contact from "../Pages/StaticPages/Contact";
import PrivacyPolicy from "../Pages/StaticPages/PrivacyPolicy";
import ResponsibleGaming from "../Pages/StaticPages/ResponsibleGaming";
import FairPlay from "../Pages/StaticPages/FairPlay";
import GamesRules from "../Pages/StaticPages/GamesRules";
import TermsConditions from "../Pages/StaticPages/TermsConditions";
import { Aviator } from "../Pages/Aviator";
import PrivateRoute from "./PrivateRoute";
import GameTennisDetails from "../Components/TennisDetails/GameDetails";
import Matka from "../Components/Matka/Matka";
import MatkaDetails from "../Components/Matka/MatkaDetails";
import GameFootDetails from "../Components/FootballDetails/GameDetails";
import BetPending from "../Pages/Reports/Currentbets";
import HomeV2 from "../Pages/HomeV2";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeV2 />} />
      <Route path="/games-all" element={<Casino />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sports/inplay" element={<Inplay />} />
      <Route
        path="/reports/account-statement"
        element={
          <PrivateRoute>
            <AccountStatementPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/profit-loss"
        element={
          <PrivateRoute>
            <ProfitLossPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/reports/bet-history"
        element={
          <PrivateRoute>
            <BetHistoryPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/withdraw"
        element={
          <PrivateRoute>
            <WithdrawPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/deposit"
        element={
          <PrivateRoute>
            <DepositPage />
          </PrivateRoute>
        }
      />
      <Route path="/bet-history" element={<BetPending/>} />
      <Route path="/games-all" element={<Casino />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/ezugi" element={<Ezugi />} />
      <Route path="/matka" element={<Matka />} />
      <Route path="/vsport" element={<Vsport />} />
      <Route path="/aviator" element={<PrivateRoute><Aviator /></PrivateRoute>} />

      <Route path="/game-details/:match_id" element={<GameDetails />} />
      <Route path="/tennis-details/:match_id" element={<GameTennisDetails />} />
      <Route path="/foot-details/:match_id" element={<GameFootDetails />} />
      <Route
        path="/change-password"
        element={
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        }
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/responsible-game" element={<ResponsibleGaming />} />
      <Route path="/fairplay" element={<FairPlay />} />
      <Route path="/game-rules" element={<GamesRules />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route
        path="/casinogame/:gameId/:providerName"
        element={
          <PrivateRoute>
            <CasinoPage />
          </PrivateRoute>
        }
      />

      <Route path="/matka-details/:match_id/:sport_id/:name" element={  <PrivateRoute><MatkaDetails/></PrivateRoute>} />
    </Routes>
  );
};
