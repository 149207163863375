import { Link } from 'lucide-react'
import React, { useState } from 'react'
import Rectangle1 from "../../Assets/Images/games/Rectangle11.png";
import Rectangle2 from "../../Assets/Images/games/Rectangle12.png";
import Rectangle3 from "../../Assets/Images/games/Rectangle13.png";
import Rectangle4 from "../../Assets/Images/games/Rectangle14.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePopularLiveGame = ({...props}) => {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        slidesToShow: 6, // Default number of slides to show
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 1030,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 426,
                settings: {
                    slidesToShow: 2,
                }
            },
        ]
      };
    
    return (
        <section className='container-fluid home-popular-casino-games mb-4'>
            <div className='d-flex justify-content-between'>
                <div className="title-4">Popular Live Games</div>
                <div className="title-4 cursor-pointer show-all">Show All</div>
            </div>
            <Slider {...settings}>
                <div class="popular-games-slick-item">
                    <img src={Rectangle1} class="img-fluid" alt="Rectangle1" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle2} class="img-fluid" alt="Rectangle2" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle3} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle4} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle1} class="img-fluid" alt="Rectangle1" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle2} class="img-fluid" alt="Rectangle2" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle3} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle4} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle1} class="img-fluid" alt="Rectangle1" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle2} class="img-fluid" alt="Rectangle2" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle3} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
                <div class="popular-games-slick-item">
                    <img src={Rectangle4} class="img-fluid" alt="Rectangle3" data-bs-interval="1000"/>
                </div>
            </Slider>
        </section>
    )
}

export default HomePopularLiveGame
