import React from 'react'
import FastAndSecurePayments from "../../Assets/Images/FastAndSecurePayments.png";
import Support247 from "../../Assets/Images/247Support.png";
import ASecureGamingSite from "../../Assets/Images/ASecureGamingSite.png";

const HomeSection1 = () => {
    return (
        <section className='container home-section-1'>
            <div className='d-flex justify-content-center align-items-end gap-5 py-5'>
                <div className='text-center'>
                    <img src={FastAndSecurePayments} width={35} alt='Fast and Secure Payments'/>
                    <h6 className='mt-2 security-text'>Fast and Secure Payments</h6>
                </div>
                <div className='text-center'>
                    <img src={ASecureGamingSite} width={25}alt='Fast and Secure Payments'/>
                    <h6 className='mt-2 security-text'>A Secure Gaming Site</h6>
                </div>
                <div className='text-center'>
                    <img src={Support247} width={20} alt='Fast and Secure Payments'/>
                    <h6 className='mt-2 security-text'>24/7 Support</h6>
                </div>
            </div>
            <div className='container text-center'>
                <h1 className='text-1'>Play 1000`s of Games</h1>
                <h4 className='text-2 fs-4 px-lg-5'>Vbet has something for everyone. With over 1000`s of games to choose from, play the best slots , jackpots and live casino games.</h4>
            </div>
        </section>
    )
}

export default HomeSection1
