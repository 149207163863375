import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ChevronLeft } from "lucide-react";

function AccountStatementPage() {
  const [Data, setData] = useState([]);
  const [from_date, setFromDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [to_date, setToDate] = useState(new Date());
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async (fromDate, toDate) => {
    try {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      const fromDateInSeconds = Math.floor(fromDateObj.getTime() / 1000);
      const toDateInSeconds = Math.floor(toDateObj.getTime() / 1000);

      const response = await axios.post(
        "https://vbet002.com/api/v5/statement",
        {
          from_date: fromDateInSeconds,
          to_date: toDateInSeconds,
          limit: 15,
          pageno: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Update state to indicate an error occurred
    }
  };

  useEffect(() => {
    fetchData(from_date, to_date);
  }, [from_date, to_date]);

  console.log(Data)
  
  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>Cashier</h2>
          </div>
          <form action="" className="filter-form g-3 row mb-3">
            <div className="col-lg-2 col-md-3 col-6">
              <div className="input-group">
                <input
                  name="date"
                  type="date"
                  autoComplete="off"
                  placeholder=""
                  className="form-control"
                  value={from_date}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="input-group">
                <input
                  name="date"
                  type="date"
                  autoComplete="off"
                  value={to_date}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder=""
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 btn-box">
              <button
                onClick={() => fetchData(from_date, to_date)}
                className="btn btn-primary rounded"
              >
                Load Report
              </button>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th width="160px">Date</th>
                  <th width="150px">Credit</th>
                  <th width="150px">Debit</th>
                  <th width="150px">Balance</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {Data &&
                  Data.map((item, index) => (
                    <tr>
                      <td className="text-nowrap">
                        {new Date(item.created_at * 1000).toLocaleString(
                          "en-US",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                      </td>
                      <td>
                        <span className="fw-medium text-success">
                          {item.amount > 0 ? item.amount : "--"}
                        </span>
                      </td>

                      <td>
                        <span className="fw-medium text-danger">
                          {item.amount < 0 ? item.amount : "--"}
                        </span>
                      </td>
                      <td>
                        <span className="fw-medium text-success">
                          {item.available_balance}
                        </span>
                      </td>
                      <td>
                        <Link to="/">
                          <span className="btn-link">{item.description}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}{" "}
                {Data &&
                  Data.map((item, index) => (
                    <tr>
                      <td className="text-nowrap">
                        {new Date(item.created_at * 1000).toLocaleString(
                          "en-US",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          }
                        )}
                      </td>
                      <td>
                        <span className="fw-medium text-success">
                          {item.amount > 0 ? item.amount : "--"}
                        </span>
                      </td>

                      <td>
                        <span className="fw-medium text-danger">
                          {item.amount < 0 ? item.amount : "--"}
                        </span>
                      </td>
                      <td>
                        <span className="fw-medium text-success">
                          {item.available_balance}
                        </span>
                      </td>
                      <td>
                        <Link to="/">
                          <span className="btn-link">{item.description}</span>
                        </Link>
                      </td>
                    </tr>
                  ))}
                {/* if there is no data */}
                {/* <tr className="data-not-found">
                  <td colspan="5" className="text-center">
                    Not Found
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default AccountStatementPage;
