import React from 'react'
import youtube_logo from "../../Assets/Images/youtube_logo.png";

const HomeSection2 = () => {
    return (
        <section className='container home-section-2'>
            <div className='container text-center'>
                <h1 className='text-1'>Login to Vbet and Play your <br/>Favorite Games</h1>
                <div className='video-box'>
                    <img src={youtube_logo} alt='Youtube Logo' width={`100px`}/>
                </div>
            </div>

        </section>
    )
}

export default HomeSection2
