import { useEffect, useState } from "react";
import cIcon1 from "../Assets/Images/Icons/Roulette_Icons.png";
import cIcon2 from "../Assets/Images/Icons/Baccarat_Icons.png";
import cIcon3 from "../Assets/Images/Icons/Andar_bahar_Icons.png";
import cIcon4 from "../Assets/Images/Icons/Poker_Icons.png";
import cIcon5 from "../Assets/Images/Icons/Blackjack_icons.png";
import cIcon6 from "../Assets/Images/Icons/casino.png";
import cIcon7 from "../Assets/Images/Icons/seven.png";
import cIcon8 from "../Assets/Images/Icons/dices.png";
import cIcon9 from "../Assets/Images/Icons/tg.png";
import cIcon10 from "../Assets/Images/Icons/Blackjack_icons.png";
import cIcon11 from "../Assets/Images/Icons/Andar_bahar_Icons.png";
import cIcon12 from "../Assets/Images/Icons/asas.png";
import whatsappIcon from "../Assets/Images/Icons/Whatsapp.svg";
import phoneIcon from "../Assets/Images/Icons/phone.png";

import banner1 from "../Assets/Images/5percent.jpg";
import banner2 from "../Assets/Images/worldcup.jpg";
import banner3 from "../Assets/Images/casino-banner.jpg";
import banner4 from "../Assets/Images/teen-patti-banner.jpg";
import menu6 from "../Assets/Images/Icons/menu6.png";
import menu5 from "../Assets/Images/Icons/menu5.png";
import menu1 from "../Assets/Images/Icons/menu1.png";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import axios from "axios";

function Home() {
  const location = useLocation();
  const { token } = useSelector((store) => store.AuthReducer);
  const [Data, setData] = useState([]);
  const [Data1, setData1] = useState([]);
  const [activeTab, setActiveTab] = useState("Roulette"); // Initial active tab
  const [loading, setLoading] = useState(false); // Loading state
  const nav = useNavigate();

  const [params, setParams] = useState({
    limit: 1000,
    category: "roulette",
  });

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getCasinoListByCategory",
        { ...params }
      );
      const data = response.data;
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const handleButtonClick = (category, limit) => {
    setActiveTab(category);
    setParams({
      limit: limit,
      category: category,
    });
  };

  const fetchDatabanner = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/our-slide",
        { domain_id: 15 }
      );
      const data = response.data.data;
      setData1(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    }
  };
  console.log(Data1);
  useEffect(() => {
    fetchData();
    fetchDatabanner();
  }, [params]);







  
  return (
    <>
      <main className="main HomePage">
        <div id="HomeBanner" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#HomeBanner"
              data-bs-slide-to={0}
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            />
            <button
              type="button"
              data-bs-target="#HomeBanner"
              data-bs-slide-to={1}
              aria-label="Slide 2"
            />
            <button
              type="button"
              data-bs-target="#HomeBanner"
              data-bs-slide-to={2}
              aria-label="Slide 3"
            />
            <button
              type="button"
              data-bs-target="#HomeBanner"
              data-bs-slide-to={3}
              aria-label="Slide 4"
            />
          </div>
          <div className="carousel-inner">
          {Data1.map((data, index) => (
            <div className="carousel-item active" data-bs-interval="2000">
              <img src={data.attachment} className="d-block w-100" alt="Banner" />
            </div>
          ))}
          </div>
        </div>
        <div className="payment-info social">
          <div className="social-icons contact-social">
            <ul>
              <li>
                <Link to="https://wa.me/447491325779">
                  <img src={whatsappIcon} alt="Whatsapp Icon" />
                  <p>
                    Whatsapp <span>24/7</span>
                  </p>
                </Link>
              </li>
              <li>
                <Link to="tel:+44 7491325779">
                  <img src={phoneIcon} alt="Phone Icon" />
                  <p>
                    Phone <span>+44 7491 325779</span>
                  </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <section className="TopCategories">

          <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
      <Link className="d-flex flex-column align-items-center py-2" to="/aviator">
        <img width={"34px"} src={menu6} alt="Aviator" />
        <span className="text-white fs-6">Aviator</span>
      </Link>
    </li>
            {[
              { category: "Roulette", icon: cIcon1, label: "Roulette" },
              { category: "baccarat", icon: cIcon2, label: "Baccarat" },
              { category: "blackjack", icon: cIcon10, label: "Black Jack" },
              { category: "andar-bahar", icon: cIcon3, label: "Andar Bahar" },
              { category: "poker", icon: cIcon4, label: "Poker" },
              { category: "teen-patti", icon: cIcon6, label: "32 Card"  },
              { category: "lottery", icon: cIcon8, label: "Lottery" },
              { category: "virtual", icon: menu5, label: "Virtual Sports" },
              { category: "dragon-tiger", icon: cIcon9, label: "Dragon Tiger" },
              { category: "table-games", icon: cIcon11, label: "Table Games" },
              { category: "holdem", icon: cIcon12, label: "Holdem" },
            ].map(({ category, icon, label }) => (
              <li
                key={category}
                className="nav-item"
                role="presentation"
                onClick={() => handleButtonClick(category, 1000)}
              >
                <button
                  type="button"
                  id={`uncontrolled-tab-example-tab-${category}`}
                  role="tab"
                  data-rr-ui-event-key={category}
                  aria-controls={`uncontrolled-tab-example-tabpane-${category}`}
                  aria-selected={activeTab === category}
                  className={`nav-link ${
                    activeTab === category ? "active" : ""
                  }`}
                  tabIndex={-1}
                >
                  <img src={icon} alt={label} />
                  {label}
                </button>
              </li>
            ))}


<li className="nav-item">
      <Link className="d-flex flex-column align-items-center py-2" to="/matka">
        <img width={"34px"} src={menu1} alt="Aviator" />
        <span className="text-white fs-6">Matka</span>
      </Link>
    </li>
          </ul>
          <div className="tab-content">
            <div
              role="tabpanel"
              id={`uncontrolled-tab-example-tabpane-${activeTab}`}
              aria-labelledby={`uncontrolled-tab-example-tab-${activeTab}`}
              className="fade tab-pane active show"
            >
              <div className="Live-Casino">
                <div className="container-lg">
                  {loading ? (
                    <div>Loading...</div> // Display loading indicator
                  ) : (
                    <div className="listings">
                      <div className="row">
                        {Data &&
                          Data.map((item, index) => (
                            <div key={index} className="col-6 col-md-3 mb-4">
                              <Link
                                to={`/casinogame/${item.game_id}/${item.provider_name}`}
                              >
                                <div className="shadow rounded position-relative">
                                  <img
                                    src={item.Url}
                                    className="img-fluid w-100"
                                    alt=""
                                    loading="lazy"
                                  />
                                </div>
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default Home;
