import React from 'react'
import youtube_logo from "../../Assets/Images/youtube_logo.png";

const HomeSection3 = () => {
    return (
        <section className='container home-section-3'>
            <div className='container'>
                <div className='home-login-box'>
                    <div className='fs-4'>
                        Join now and get 100% bonus on Sign up.<br/>
                        It only takes a minute.
                    </div>
                    <div>
                        <btn className='btn login-btn'>JOIN NOW</btn>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default HomeSection3
