import axios from "axios";
import { Ticket } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

function BetSlip() {
  const params=useParams()
  const [Data, setData] = useState([]);
  const [from_date, setFromDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [to_date, setToDate] = useState(new Date());
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async (fromDate, toDate) => {
    try {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      const fromDateInSeconds = Math.floor(fromDateObj.getTime() / 1000);
      const toDateInSeconds = Math.floor(toDateObj.getTime() / 1000);

      const response = await axios.post(
        "https://vbet002.com/api/v5/list-bt-ssn-mk",
        {
          
          limit: 10,
         fancy_id:"0",
          match_id: params.match_id,
          market_id: "0",
          
          pageno: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data.data.MatchAndBetfair
      );
      console.log(response)
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Update state to indicate an error occurred
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => fetchData(), 5000);
    return () => clearInterval(intervalId);
  }, []);



  return (
    <>
      <div className="betslip">
        <div className="betslip-header">Betslip</div>
       
        {Data && Data.length === 0 ? (
           <div className="betslip-body">
          <div className="blank-data">
            <Ticket size={35} className="blank-icon" />
            <p>Your Betlist is empty</p>
            <span>Click on odds to add a bet to the betlist</span>
          </div>
          </div>
            ) : (
          <div className="table-responsive px-3">
            <table className="table statement-table table-bordered w-100">
              <thead>
                <tr >
                  <th className="text-black">Description</th>
                  <th className="text-black" width="160px">Market</th>
                  <th className="text-black" width="160px">Odds</th>
                  <th className="text-black" width="160px">Amount</th>

                </tr>
              </thead>
              <tbody >
                {Data &&
                  Data.map((item, index) => (
                    <tr>
                      <td className="text-">
                      
                        <span className="d-block text-black">{item.
                          selectionName
                        }</span>
                        <span className="d-block text-black">

                          {new Date(item.created_at * 1000).toLocaleString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </span>
                      </td>
                      <td className="text-black">

                        {item.marketName}
                        <span className="d-flex justify-content-end fw-bold">
                          {item.Type}
                        </span>
                      </td>
                      <td className="text-black">{item.odds}</td>
                      <td className="text-black">{item.stack}</td>


                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
            )}




        </div>
   
    </>
  );
}

export default BetSlip;
