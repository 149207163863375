function FairPlay() {
  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>Fair Play</h2>
          </div>
          <div className="fairplay">
            <p>
              Vbet treats all Players equally and respectfully. Vbet Provide
              Fair Odds and Fair winnings. Fair competition, respect,
              friendship, team spirit, equality, sport without doping, respect
              for written and unwritten rules such as integrity, solidarity,
              tolerance, care, excellence and joy, are the building blocks of
              fair play that can be experienced and learnt both on and off the
              field.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default FairPlay;
