import { Link } from "react-router-dom";
import icon1 from "../../Assets/Images/Icons/Whatsapp.svg";
import icon2 from "../../Assets/Images/Icons/Telegram.svg";
import icon3 from "../../Assets/Images/Icons/Instagram.svg";
import icon4 from "../../Assets/Images/Icons/facebook.svg";
function Contact() {
  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>Contact Us</h2>
          </div>
          <div className="payment-info">
            <div className="social-icons contact-social">
              <ul className="mb-0 justify-content-start ps-0">
                <li className="m-1">
                  <Link className="panel" to="#" target="_blank">
                    <img width={40} src={icon1} alt="Whatsapp Icon" />
                  </Link>
                </li>
                <li className="m-1">
                  <Link to="#" target="">
                    <img width={40} src={icon2} alt="Telegram Icon" />
                  </Link>
                </li>
                <li className="m-1">
                  <Link to="#" target="">
                    <img width={40} src={icon3} alt="Instagram Icon" />
                  </Link>
                </li>
                <li className="m-1">
                  <Link to="#" target="">
                    <img width={40} src={icon4} alt="Facebook Icon" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;
