import axios from "axios";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
function Casino() {
  const [Data, setData] = useState([]);
  const nav = useNavigate();

  const [params, setParams] = useState({
    limit: 1000,
    category: "live-casino",
  });

  const fetchData = async () => { 
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getCasinoListByCategory",
        { ...params }
      );
      const data = response.data;
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    }
  };

useEffect(()=>{
  fetchData()
},[])

  return (
    <>
      <main className="main">
        <div className=" container">
          <div className="PagesHeading">
            <h2>Live Casino</h2>
            <Link to="/">
            <div className="back-link">
              Back <ChevronLeft />
            </div>
            </Link>
          </div>
          <div className="listings">
          <div className="row">
                    {Data && Data.map((item,index)=>(
                           <div className="col-6 col-md-3 mb-4">
                         
                        <Link
                     
                       
                        to={`/casinogame/${item.game_id}/${item.provider_name}`}
                     
                      >
                           <div  className="shadow rounded position-relative">
                             <img
                                key={index}
                               src={item.Url}
                               className="img-fluid w-100"
                               alt=""
                             
                             />
                           </div>
                           </Link>
                     
                         </div>
                      ))}
                     
                     
                    </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Casino;
