import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
function Matka() {
    const [data, setData] = useState();
    const [UpCommingMatches, setUpCommingMatches] = useState([]);
    const [InplayMatches, setInplayMatches] = useState([]);

    const fetchData = async () => {
        const params = {
            limit: 50,
            pageno: 1,
            series_id: 0,
            sport_id: 222,
        };

        try {
            const response = await axios.post(
                "https://easybet24.us/api/v5/getseiresMatchsList",
                { ...params }
            );
            const data = response.data;
            setData(data.data);
            console.log(response)

            setUpCommingMatches(data.data.UpCommingMatches);
            setInplayMatches(data.data.InplayMatches);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // console.log(data)
    // console.log(InplayMatches);
    // console.log("hello");
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <>
            <section className="wrapper">
                <div className="report-box casino-box">
                    <div className="center-main-content container-fluid pt-5">
                        <div className="w-100 pt-5">
                            <div className="row mt-5 matka-main">
                                {InplayMatches &&
                                    InplayMatches.map((item, index) => (
                                        <div className="col-md-3 col-6 mb-3">
                                            <Link to={`/matka-details/${item.match_id}/${item.sport_id}/${item.name}`}>
                                                <div className="matka-box">
                                                    <div className="box-desk-in">
                                                        <p className="text-info">
                                                            <strong>
                                                                {new Date(item.start_date * 1000).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        day: "2-digit",
                                                                        month: "short",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    }
                                                                )}
                                                            </strong>
                                                            &nbsp;|&nbsp;
                                                            <strong>
                                                                {new Date(item.end_date * 1000).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        day: "2-digit",
                                                                        month: "short",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    }
                                                                )}
                                                            </strong>{" "}
                                                            &nbsp;|&nbsp;
                                                            <strong>
                                                                {new Date(item.draw_date * 1000).toLocaleString(
                                                                    "en-US",
                                                                    {
                                                                        day: "2-digit",
                                                                        month: "short",
                                                                        year: "numeric",
                                                                        hour: "2-digit",
                                                                        minute: "2-digit",
                                                                        hour12: true,
                                                                    }
                                                                )}
                                                            </strong>
                                                        </p>
                                                        <hr />
                                                        <span className="for_mobile_result">{item.name}</span>
                                                        <div className="d-flex align-items-center justify-content-center mt-2">
                                                            <span className="text-info1">**</span>
                                                            <img
                                                                alt=""
                                                                src="https://easybet24.us/assets/images/new.gif"
                                                            />
                                                            <span className="text-info1">**</span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center mt-2">
                                                            {/* <p className="fw-bold">Left Time 02:54:46</p> */}
                                                            <p className="text-button ms-0">{item.InplayStatus}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Matka;
