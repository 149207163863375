import liveIcon from "../Assets/Images/Icons/live.png";
import cricketIcon from "../Assets/Images/Icons/cricket.png";
import tennisIcon from "../Assets/Images/Icons/tennis.png";
import footballIcon from "../Assets/Images/Icons/football.png";
import { Link } from "react-router-dom";
import { Menu, Play, Ticket, X } from "lucide-react";
import coom from "../Assets/Images/coming-soon.png";

import $ from "jquery";
import SideNav from "../Components/SideNav";
import BetSlip from "../Components/BetSlip";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
const showSidenav = () => {
  $(".sidenav-menu").toggleClass("active");
};
function Inplay() {

  const { token } = useSelector((store) => store.AuthReducer);
  const [UpCommingMatches, setUpCommingMatches] = useState([]);
  const [InCommingMatches, setInCommingMatches] = useState([]);
  const [UpCommingMatches1, setUpCommingMatches1] = useState([]);
  const [UpCommingMatches2, setUpCommingMatches2] = useState([]);

  const Tennis = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 2,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatches2(data.data.UpCommingMatches);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const Football = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 1,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatches1(data.data.UpCommingMatches);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  const Cricket = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 4,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatches(data.data.UpCommingMatches);
      setInCommingMatches(data.data.InCommingMatches);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    }
  };

  const handleGameClick = (gameId) => {
    if (token) {
      window.location.href = `/cricketdetail/${gameId}`;
    } else {

    }
  };

  useEffect(() => {
    Cricket();
    Football()
    Tennis()
  }, []);



  const [UpCommingMatchescrick, setUpCommingMatchescrick] = useState([]);
  const [UpCommingMatchestennis, setUpCommingMatchestennis] = useState([]);
  const [UpCommingMatchesfoot, setUpCommingMatchesfoot] = useState([]);

  const fetchData = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 4,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatchescrick(data.data.InplayMatches);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    }
  };

  const fetchData1 = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 1,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatchesfoot(data.data.InplayMatches);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData2 = async () => {
    const params = {
      limit: 50,
      pageno: 1,
      series_id: 0,
      sport_id: 2,
      type: "home",
    };

    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getseiresMatchsList",
        { ...params }
      );
      const data = response.data;
      setUpCommingMatchestennis(data.data.InplayMatches);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchData1();
    fetchData2();
  }, []);


  return (
    <>
      <main className="main inPlay text-dark bg-light h-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <button
                className="btn border-0 mb-2 text-primary p-0"
                onClick={showSidenav}
              >
                <Menu />
              </button>
            </div>
            <div className="col-md-9 pe-md-0">
              <div className="d-flex gap-2 sidenav-menu align-items-baseline">
                <SideNav />
                <div className="w-100">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-inplay-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-inplay"
                        type="button"
                        role="tab"
                        aria-controls="pills-inplay"
                        aria-selected="true"
                      >
                        <img src={liveIcon} width="20" />
                        Inplay
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-cricket-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-cricket"
                        type="button"
                        role="tab"
                        aria-controls="pills-cricket"
                        aria-selected="false"
                      >
                        <img src={cricketIcon} width="20" />
                        Cricket
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-tennis-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-tennis"
                        type="button"
                        role="tab"
                        aria-controls="pills-tennis"
                        aria-selected="false"
                      >
                        <img src={tennisIcon} width="20" />
                        Tennis
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-soccer-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-soccer"
                        type="button"
                        role="tab"
                        aria-controls="pills-soccer"
                        aria-selected="false"
                      >
                        <img src={footballIcon} width="20" />
                        Soccer
                      </button>
                    </li>

                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade "
                      id="pills-cricket"
                      role="tabpanel"
                      aria-labelledby="pills-cricket-tab"
                      tabIndex={0}
                    >
                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={cricketIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Cricket
                          <span className="counter">{UpCommingMatches.length}</span>
                        </div>
                      </div>
                      {/* cricket starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatches && UpCommingMatches.length > 0 ? (UpCommingMatches.map((item, index) => (
                          <Link to={`/game-details/${item.match_id}`}>
                            <div className="market-data">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="team-market gap-2 align-items-center d-flex">
                                    <div className="date-time in-play">

                                      {new Date(
                                        parseInt(item.start_date) * 1000
                                      ).toLocaleDateString()}
                                    </div>
                                    <div className="game-name">{item.name}</div>
                                  </div>
                                </div>
                                <div className="col-md-4 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                  <div className="row justify-content-end">
                                    <div className="col-lg-12">
                                      <div className="row matchrate">

                                        {/* back loop */}
                                        {item?.runner_json?.map((i, idx) => (
                                          <>
                                            <div className="col-3 px-1">
                                              <div className="back-rate back">
                                                {i?.ex?.availableToBack[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                              </div>
                                            </div>
                                            {/* <div className="col-3 px-1">
                                            <div className="lay-rate lay">
                                              -
                                            </div>
                                          </div> */}
                                            <div className="col-3 px-1">
                                              <div className="lay-rate lay">
                                                {i?.ex?.availableToLay[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                              </div>
                                            </div></>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                          ))) : (
                            <div className="bg-black">
                            <img  height={"60px"} src={coom} /> 
                            </div>
                          )}
                        {/* main loop ends here */}
                        {/* main loop starts here */}
                        {/* main loop ends here */}
                      </div>
                      {/* cricket ends here */}
                    </div>
                    <div
                      className="tab-pane fade "
                      id="pills-tennis"
                      role="tabpanel"
                      aria-labelledby="pills-tennis-tab"
                      tabIndex={0}
                    >
                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={tennisIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Tennis
                          <span className="counter">{UpCommingMatches2.length}</span>
                        </div>
                      </div>
                      {/* tennis starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatches2 && UpCommingMatches2.length > 0 ? (
                          UpCommingMatches2.map((item, index) => (
                            <Link to={`/tennis-details/${item.match_id}`}>
                              <div className="market-data">
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="team-market gap-2 align-items-center d-flex">
                                      <div className="date-time in-play">

                                        {new Date(
                                          parseInt(item.start_date) * 1000
                                        ).toLocaleDateString()}
                                      </div>
                                      <div className="game-name">{item.name}</div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                    <div className="row justify-content-end">
                                      <div className="col-lg-10">
                                        <div className="row matchrate">

                                          {/* back loop */}
                                          {item?.runner_json?.map((i, idx) => (
                                            <>
                                              <div className="col-3 px-1">
                                                <div className="back-rate back">
                                                  {i?.ex?.availableToBack[0]?.price}
                                                  <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                                </div>
                                              </div>
                                              <div className="col-3 px-1">
                                                <div className="lay-rate lay">
                                                  {i?.ex?.availableToLay[0]?.price}
                                                  <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                                </div>
                                              </div></>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          ))) : (
                            <div className="bg-black">
                            <img  height={"60px"} src={coom} /> 
                            </div>
                          )}
                        {/* main loop ends here */}
                        {/* main loop starts here */}

                        {/* main loop ends here */}
                      </div>
                      {/* tennis ends here */}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-soccer"
                      role="tabpanel"
                      aria-labelledby="pills-soccer-tab"
                      tabIndex={0}
                    >
                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={footballIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Football
                          <span className="counter">{UpCommingMatches1.length}</span>
                        </div>
                      </div>
                      {/* football starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatches1 && UpCommingMatches1.length > 0 ? (UpCommingMatches1 && UpCommingMatches1.map((item, index) => (
                          <Link to={`/foot-details/${item.match_id}`}>
                            <div className="market-data">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="team-market gap-2 align-items-center d-flex">
                                    <div className="date-time in-play">

                                      {new Date(
                                        parseInt(item.start_date) * 1000
                                      ).toLocaleDateString()}
                                    </div>
                                    <div className="game-name">{item.name}</div>
                                  </div>
                                </div>
                                <div className="col-md-6 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                  <div className="row justify-content-end">
                                    <div className="col-lg-10">
                                      <div className="row matchrate">

                                        {/* back loop */}
                                        {item?.runner_json?.map((i, idx) => (
                                          <>
                                            <div className="col-2 px-1">
                                              <div className="back-rate back">
                                                {i?.ex?.availableToBack[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                              </div>
                                            </div>
                                            <div className="col-2 px-1">
                                              <div className="lay-rate lay">
                                                {i?.ex?.availableToLay[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                              </div>
                                            </div></>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                      ))) : (
                        <div className="bg-black">
                        <img  height={"60px"} src={coom} /> 
                        </div>
                      )}
                        {/* main loop ends here */}
                        {/* main loop starts here */}

                        {/* main loop ends here */}
                      </div>
                      {/* football ends here */}
                    </div>
                    <div
                      className="tab-pane fade show active"
                      id="pills-inplay"
                      role="tabpanel"
                      aria-labelledby="pills-inplay-tab"
                      tabIndex={0}
                    >
                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={cricketIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Cricket
                          <span className="counter">({UpCommingMatchescrick.length})</span>
                        </div>
                      </div>
                      {/* cricket starts here */}

                      {/* main loop starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatchescrick && UpCommingMatchescrick.length > 0 ? (UpCommingMatchescrick && UpCommingMatchescrick.map((item, index) => (
                          <Link to={`/game-details/${item.match_id}`}>
                            <div className="market-data">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="team-market gap-2 align-items-center d-flex">
                                    <div className="date-time in-play">
                                      <Play size="12" />
                                      In-Play
                                    </div>
                                    <div className="game-name">{item.name}</div>
                                  </div>
                                </div>
                                <div className="col-md-4 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                  <div className="row justify-content-end">
                                    <div className="col-lg-10">
                                      <div className="row matchrate">

                                        {/* back loop */}
                                        {item?.runner_json?.map((i, idx) => (
                                          <>
                                            <div className="col-3 px-1">
                                              <div className="back-rate back">
                                                {i?.ex?.availableToBack[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                              </div>
                                            </div>
                                            <div className="col-3 px-1">
                                              <div className="lay-rate lay">
                                                {i?.ex?.availableToLay[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                              </div>
                                            </div></>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))) : (
                          <div className="bg-black">
                          <img  height={"60px"} src={coom} /> 
                          </div>
                        )}
                        {/* main loop ends here */}
                        {/* main loop starts here */}
                        {/* main loop ends here */}
                      </div>
                      {/* main loop ends here */}


                      {/* cricket ends here */}

                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={tennisIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Tennis
                          <span className="counter">({UpCommingMatchestennis.length})</span>
                        </div>
                      </div>
                      {/* tennis starts here */}

                      {/* main loop starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatchestennis && UpCommingMatchestennis.map((item, index) => (
                          <Link to={`/tennis-details/${item.match_id}`}>
                            <div className="market-data">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="team-market gap-2 align-items-center d-flex">
                                    <div className="date-time in-play">
                                      <Play size="12" />
                                      In-Play
                                    </div>
                                    <div className="game-name">{item.name}</div>
                                  </div>
                                </div>
                                <div className="col-md-4 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                  <div className="row justify-content-end">
                                    <div className="col-lg-10">
                                      <div className="row matchrate">

                                        {/* back loop */}
                                        {item?.runner_json?.map((i, idx) => (
                                          <>
                                            <div className="col-3 px-1">
                                              <div className="back-rate back">
                                                {i?.ex?.availableToBack[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                              </div>
                                            </div>
                                            <div className="col-3 px-1">
                                              <div className="lay-rate lay">
                                                {i?.ex?.availableToLay[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                              </div>
                                            </div></>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}

                      </div>



                      {/* tennis ends here */}

                      <div className="collapse-bg">
                        <div className="collapse-title">
                          <img
                            src={footballIcon}
                            width="18"
                            className="me-1"
                            alt=""
                          />
                          Football
                          <span className="counter">({UpCommingMatchesfoot.length})</span>
                        </div>
                      </div>
                      {/* football starts here */}
                      <div className="market-list">
                        {/* main loop starts here */}
                        {UpCommingMatchesfoot && UpCommingMatchesfoot.length > 0 ? (UpCommingMatches.map((item, index) => (
dex) => (
                          <Link to={`/game-details/${item.match_id}`}>
                            <div className="market-data">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="team-market gap-2 align-items-center d-flex">
                                    <div className="date-time in-play">
                                      <Play size="12" />
                                      In-Play
                                    </div>
                                    <div className="game-name">{item.name}</div>
                                  </div>
                                </div>
                                <div className="col-md-4 ps-md-0 pe-md-4 mt-md-0 mt-3">
                                  <div className="row justify-content-end">
                                    <div className="col-lg-10">
                                      <div className="row matchrate">

                                        {/* back loop */}
                                        {item?.runner_json?.map((i, idx) => (
                                          <>
                                            <div className="col-3 px-1">
                                              <div className="back-rate back">
                                                {i?.ex?.availableToBack[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToBack[0]?.size}</small>
                                              </div>
                                            </div>
                                            <div className="col-3 px-1">
                                              <div className="lay-rate lay">
                                                {i?.ex?.availableToLay[0]?.price}
                                                <small className="d-block">{i?.ex?.availableToLay[0]?.size}</small>
                                              </div>
                                            </div></>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                       ))) : (
                        <div className="bg-black">
                        <img  height={"60px"} src={coom} /> 
                        </div>
                      )}
                        {/* main loop ends here */}
                        {/* main loop starts here */}
                        {/* main loop ends here */}
                      </div>
                      {/* football ends here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 d-md-block d-none">
              <BetSlip />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Inplay;
