import HomeBanner from "./home/HomeBanner";
import ContactSocial from "./home/ContactSocial";
import TopCategories from "./home/TopCategories";
import HomeSection1 from "./home/HomeSection1";
import HomePopularGames from "./home/HomePopularGames";
import HomePopularCasinoGame from "./home/HomePopularCasinoGame";
import HomePopularLiveGame from "./home/HomePopularLiveGame";
import HomeSection2 from "./home/HomeSection2";
import HomeSection3 from "./home/HomeSection3";

function HomeV2() {
  

  return (
    <>
      <main className="main">
        <div className="HomePage">
          <TopCategories />
          <HomeBanner />
          <ContactSocial />
          <div className="home-sections">
            <HomeSection1/>
            <HomePopularGames/>
            <HomePopularCasinoGame/>
            <HomePopularLiveGame/>
            <HomeSection2/>
            <HomeSection3/>
          </div>
        </div>
      </main>
    </>
  );
}

export default HomeV2;
