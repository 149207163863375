import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import cIcon1 from "../Assets/Images/Icons/Roulette_Icons.png";
import cIcon2 from "../Assets/Images/Icons/Baccarat_Icons.png";
import cIcon3 from "../Assets/Images/Icons/Andar_bahar_Icons.png";
import cIcon5 from "../Assets/Images/Icons/Blackjack_icons.png";
import cIcon6 from "../Assets/Images/Icons/casino.png";
import cIcon7 from "../Assets/Images/Icons/seven.png";
import cIcon8 from "../Assets/Images/Icons/dices.png";
import cIcon9 from "../Assets/Images/Icons/tg.png";
import Cookies from "js-cookie";
import HomeServices from "../services/HomeServices";
import { error } from "jquery";

export const Ezugi = () => {
  const { token } = useSelector((store) => store.AuthReducer);
  const [Data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState("Roulette"); // Initial active tab
  const [loading, setLoading] = useState(false); // Loading state
  const [lobbyURL, setLobbyURL] = useState(""); // Loading state
  const nav = useNavigate();

  const [params, setParams] = useState({
    limit: 1000,
    category: "roulette",
  });

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/getCasinoListByCategory",
        { ...params }
      );
      const data = response.data;
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error gracefully, maybe set an error state to display to the user
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  const handleButtonClick = (category, limit) => {
    setActiveTab(category);
    setParams({
      limit: limit,
      category: category,
    });
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  useEffect(() => {
    generateLobbyURL();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Cookies.get("token")]);

  const generateLobbyURL = () => {
    setLobbyURL("");
    HomeServices?.generateLobbyURL()?.then(({data:response})=>{
      if(response?.status===200){
        setLobbyURL(response?.data);
      }
    }).catch(error=>{
      console.error("error",error);
    })
  }

  return (
    <main className="main">
      {/* <section className="TopCategories">
        <ul className="nav nav-tabs" role="tablist">
          {[
            { category: "Roulette", icon: cIcon1, label: "Roulette" },
            { category: "baccarat", icon: cIcon2, label: "Baccarat" },
            { category: "blackjack", icon: cIcon5, label: "Black Jack" },
            { category: "andar-bahar", icon: cIcon3, label: "Andar Bahar" },
            { category: "teen-patti", icon: cIcon6, label: "32 Card" },
            { category: "lottery", icon: cIcon8, label: "Lottery" },
            { category: "dragon-tiger", icon: cIcon9, label: "Dragon Tiger" },
          ].map(({ category, icon, label }) => (
            <li
              key={category}
              className="nav-item"
              role="presentation"
              onClick={() => handleButtonClick(category, 1000)}
            >
              <button
                type="button"
                id={`uncontrolled-tab-example-tab-${category}`}
                role="tab"
                data-rr-ui-event-key={category}
                aria-controls={`uncontrolled-tab-example-tabpane-${category}`}
                aria-selected={activeTab === category}
                className={`nav-link ${activeTab === category ? "active" : ""}`}
                tabIndex={-1}
              >
                <img src={icon} alt={label} />
                {label}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          <div
            role="tabpanel"
            id={`uncontrolled-tab-example-tabpane-${activeTab}`}
            aria-labelledby={`uncontrolled-tab-example-tab-${activeTab}`}
            className="fade tab-pane active show"
          >
            <div className="Live-Casino">
              <div className="container-lg">
                {loading ? (
                  <div>Loading...</div> // Display loading indicator
                ) : (
                  <div className="listings">
                    <div className="row">
                      {Data &&
                        Data.map((item, index) => (
                          <div key={index} className="col-6 col-md-3 mb-4">
                            <Link
                              to={`/casinogame/${item.game_id}/${item.provider_name}`}
                            >
                              <div className="shadow rounded position-relative">
                                <img
                                  src={item.Url}
                                  className="img-fluid w-100"
                                  alt=""
                                  loading="lazy"
                                />
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="container text-center">
        {
          lobbyURL!==""? 
          <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item w-100" style={{minHeight:(window?.innerHeight/2)}} src={lobbyURL} allowFullScreen title="Ezugi"></iframe>
          </div> : <h2>Data not Found</h2>
        }
      </section>
    </main>
  );
};
