import axios from "axios";
import { Minus, Plus, Ticket, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const priceArr = [100,500, 1000, 5000, 10000, 50000];



function BetInput3(
  {
    run,
    size,
    sport_id,
    fancyStatus,
    setbetVisible3,
    setObj2,
    obj2,
    fancy_id, 
    is_back,
    betvisible3,
    active,
    setActive,
  }
) {

  const [profit, SetProfit]=useState()
  const [loss, SetLoss]=useState(0)
 
  const params = useParams();
  const [loading, setLoading] = useState(false);
 
  const { market_id, odds, selection_id, color,  } = betvisible3;

  const { token } = useSelector((store) => store.AuthReducer);
  const [obj, setObj] = useState({}); // Initialize obj state variable


  useEffect(() => {
    if (betvisible3) {
      const { market_id, odds, selection_id, color,is_back } = betvisible3;
      setObj({
        market_id: market_id || 0,
        selection_id: selection_id,
        odds: odds,
        is_back:is_back,
        match_id: params.match_id,
      });
    }
  }, [betvisible3, params.match_id]); // Add dependencies

  const placeBet = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://vbet002.com/api/v5/save-bet",
        { ...obj },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast[response.data.error ? "error" : "success"](response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }); 
    setbetVisible3(false)
    } catch (error) {
      console.error("Error placing bet:", error);
    } finally {
      setLoading(false);
    }
  };



  const handlePrice = (value) => {
    setObj({
      ...obj,
      stack: value,
    });
    if (betvisible3) {
      const { odds,color} = betvisible3;
      setObj2({
        ...obj2,
        stack: value,
        odds: odds,
       color:color,
     
      });
    }
  };

  const [odds1, setOdds] = useState(Number(odds));

  const increaseOdds = () => {
    setOdds(odds1 + 0.01);
     if (betvisible3) {
      const { odds,color} = betvisible3;
      setObj2({
        ...obj2,
        stack:  obj.stack,
        odds: odds1,
       color:color,
     
      });
    }
  };

  const decreaseOdds = () => {
    setOdds(odds1 - 0.01);
     if (betvisible3) {
      const { odds,color} = betvisible3;
      setObj2({
        ...obj2,
        stack:  obj.stack,
        odds: odds1,
       color:color,
     
      });
    }
  };




  return (
    <>

      {/* bet slip starts here */}
      <div className="row justify-content-end">
        <div className="col-md-8">
          <div className="bet-input-container">
            {/* back data */}
            <div className={`bs-top-content ${color}`}>
            <div className="spin input-group d-flex align-items-center">
      <Minus className="cursor-pointer" onClick={decreaseOdds} />
      <h6 className="mb-0 mx-3">{odds1.toFixed(2)}</h6>
      <Plus className="cursor-pointer" onClick={increaseOdds} />


      {/* <div className="ms-3">
      <span className="text-success">Profit-{color=='back'? Math.floor((odds % 1) * 100) * (obj.stack / 100) || 0 :obj.stack || 0}</span>
        <span className="text-danger ms-5">Loss-{color=='back'? obj.stack || 0 :Math.floor((odds % 1) * 100) * (obj.stack / 100) || 0}</span>
      </div> */}
    </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Custom"
                  value={obj?.stack}
                  name="odds"
                    min="1.01"
                    max="1000"
                    onChange={(e) => setObj({ ...obj, stack: e.target.value })}
                   
                />
              </div>
              <button className="btn btn-small border-0 p-0" onClick={() =>{ setbetVisible3(false); setObj2({}); }}>
                <X size={16} />
              </button>
            </div>

            <div className="bid-rate betslip-button d-flex gap-2">
            {priceArr?.map((i, index) => (
              <button
                key={index}
                onClick={() => handlePrice(i)}
                className="btn btn-outline-info btn-sm"
              >
                {i}
              </button>
            ))}
          </div>
            <div className="row mx-0 justify-content-end">
             
              <div className="col-md-8 mt-2">
                <p>
                  Please select a Stake Amount. Enter a custom
                  amount by clicking on the custom box.
                </p>
              </div>
              <div className="col-md-4 mt-md-2 text-end">
                <button className="btn btn-primary"
                 onClick={() =>
                  placeBet({
                    fancyStatus,
                    fancy_id,
                    is_back,
                    match_id: params?.id,
                    run: String(run),
                    size: obj?.size,
                    sport_id: obj?.sport_id,
                    stack: obj?.stack,
                  })
                }>
                  Place Bet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bet slip ends here */}
    </>
  );
}

export default BetInput3;
