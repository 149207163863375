import axios from "axios";
import { Minus, Plus, Ticket, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const priceArr = [100,500, 1000, 5000, 10000, 50000];



function BetInputFancy(
  {
    size,
  sport_id,
  fancyStatus,
  setbetVisible,
  betVisible,
  fancy_id,
  is_back,
 odds
  }
) {


  const params = useParams();
  const [loading, setLoading] = useState(false);

  const { run, color } = betVisible;

  const { token } = useSelector((store) => store.AuthReducer);
  const [obj, setObj] = useState({}); // Initialize obj state variable

  console.log(obj);

  useEffect(() => {
    if (betVisible) {
      const {  fancyStatus, fancy_id,run,size, color,is_back } = betVisible;
      setObj({
        
        fancyStatus: fancyStatus,
        fancy_id: fancy_id,
        run: run,
        size:size,
        is_back:is_back,
        sport_id:"4",
        match_id: params.match_id,
      });
    }
  }, [betVisible, params.match_id]); // Add dependencies
  console.log(obj)

  const placeBet = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://vbet002.com/api/v5/save-ssn-bet",
        { ...obj },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        
      );
      console.log(response)
      toast[response.data.error ? "error" : "success"](response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setbetVisible(false)
    } catch (error) {
      console.error("Error placing bet:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrice = (value) => {
    setObj({
      ...obj,
      stack: value,
    });
  };
  const [odds1, setOdds] = useState(Number(run));

  const increaseOdds = () => {
    setOdds(odds1 + 1);
  };

  const decreaseOdds = () => {
    setOdds(odds1 - 1);
  };


  return (
    <>

      {/* bet slip starts here */}
      <div className="row justify-content-end">
        <div className="col-md-8">
          <div className="bet-input-container">
            {/* back data */}
            <div className={`bs-top-content ${color}`}>
            <div className="spin input-group d-flex align-items-center">
      <Minus className="cursor-pointer" onClick={decreaseOdds} />
      <h6 className="mb-0 mx-3">{odds1}</h6>
      <Plus className="cursor-pointer" onClick={increaseOdds} />
  
    </div>

    
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Custom"
                  value={obj?.stack}
                  name="odds"
                    min="1.01"
                    max="1000"
                    onChange={(e) => setObj({ ...obj, stack: e.target.value })}
                   
                />
              </div>
              <button className="btn btn-small border-0 p-0" onClick={() => setbetVisible(false)}>
                <X size={16} />
              </button>
            </div>

            <div className="bid-rate betslip-button d-flex gap-2">
            {priceArr?.map((i, index) => (
              <button
                key={index}
                onClick={() => handlePrice(i)}
                className="btn btn-outline-info btn-sm"
              >
                {i}
              </button>
            ))}
          </div>
            <div className="row mx-0 justify-content-end">
             
              <div className="col-md-8 mt-2">
                <p>
                  Please select a Stake Amount. Enter a custom
                  amount by clicking on the custom box.
                </p>
              </div>
              <div className="col-md-4 mt-md-2 text-end">
                <button className="btn btn-primary"
                onClick={() =>
                  placeBet({
                    fancyStatus,
                    fancy_id,
                    is_back,
                    match_id: params?.id,
                    run: String(run),
                    size: obj?.size,
                    sport_id: obj?.sport_id,
                    stack: obj?.stack,
                  })
                }>
                  Place Bet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bet slip ends here */}
    </>
  );
}

export default BetInputFancy;
