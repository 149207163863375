import axios from "axios";
import Cookies from "js-cookie";

axios.interceptors.request.use(
	function (config) {
		let token = Cookies.get("token");
		if(token){
			config.headers["Authorization"] = `Bearer ${token}`;
		}		
		config.headers["X-XSS-Protection"] = `1; mode=block`;
		config.headers["X-Content-Type-Options"] = "nosniff";
		config.headers["Referrer-Policy"] = "same-origin";
		config.headers["X-Content-Security-Policy"] = "default-src 'self'; script-src 'self';";
        config.headers["Client-Service"] = process.env.REACT_APP_CLIENT_SERVICE;
        config.headers["Auth-Key"] = process.env.REACT_APP_AUTH_KEY;
		
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (
			(error.response.status === 401 || error.response.status === 403) &&
			error.response.statusText === "Unauthorized"
		) {
			
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
		return Promise.reject(error);
	}
);

export default axios;
