import liveIcon from "../Assets/Images/Icons/live.png";
import cricketIcon from "../Assets/Images/Icons/cricket.png";
import tennisIcon from "../Assets/Images/Icons/tennis.png";
import footballIcon from "../Assets/Images/Icons/football.png";
import { Link } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
import $ from "jquery";
function SideNav() {
  const showSidenav = () => {
    $(".sidenav-menu").toggleClass("active");
  };
  return (
    <>
      <div id="mobile-nav" className="sidenav scroller">
        <button
          onClick={showSidenav}
          className="btn close-menu d-md-none d-block"
        >
          <ArrowLeft />
        </button>
        <div className="sidenav-bg sidenav-height">
          <ul className="collapsible">
            <li className="active">
              <Link to="/sports/inplay">
                <div className="icon-holder-small">
                  <img src={liveIcon} width="20" />
                </div>
                <span className="link">Live Events</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="sidenav-bg sidenav-height">
          <ul className="collapsible">
            <li className="active">
              <Link
                to="#"
                id="pills-cricket-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-cricket"
                type="button"
                role="tab"
                aria-controls="pills-cricket"
                aria-selected="false"
              >
                <div className="icon-holder-small">
                  <img src={cricketIcon} width="20" />
                </div>
                <span className="link">Cricket</span>
              </Link>
            </li>
            <li className="">
              <Link to="#">
                <div className="icon-holder-small">
                  <img src={tennisIcon} width="20" />
                </div>
                <span className="link">Tennis</span>
              </Link>
            </li>
            <li className="">
              <Link to="#">
                <div className="icon-holder-small">
                  <img src={footballIcon} width="20" />
                </div>
                <span className="link">Soccer</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SideNav;
