import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import gif from "../Assets/Images/gif.gif"
export const Aviator = () => {

  const [Data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/game-lobby",
        { gameId:"201206" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <main className="main" >
        {loading ? (
          <div className="px-5 text-center">
            <img src={gif} width={140} className="d-block mt-5 pt-5 m-auto" />
            <h6 className="fw-normal mt-2">Loading...</h6>
          </div>
        ) : (
          <iframe
            width={"100%"}
            height="550px"
            src={Data?.data?.url}
            frameBorder="0"
          ></iframe>
        )}
      </main>
    </>
  );
}
