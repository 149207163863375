import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ChevronLeft } from "lucide-react";
function ProfitLossPage() {
  const [Data, setData] = useState([]);
  const [from_date, setFromDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [to_date, setToDate] = useState(new Date());
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async (fromDate, toDate) => {
    try {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      const fromDateInSeconds = Math.floor(fromDateObj.getTime() / 1000);
      const toDateInSeconds = Math.floor(toDateObj.getTime() / 1000);

      const response = await axios.post(
        "https://vbet002.com/api/v5/my-profit-loss",
        {
          from_date: fromDateInSeconds,
          to_date: toDateInSeconds,
          limit: 15,
          betType: "P",
          match_id: "0",
          sport_id: "0",
          pageno: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Update state to indicate an error occurred
    }
  };

  useEffect(() => {
    fetchData(from_date, to_date);
  }, [from_date, to_date]);

  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>Profit and Loss</h2>
          </div>
          <form action="" className="filter-form g-3 row mb-3">
            <div className="col-lg-2 col-md-3 col-6">
              <div className="input-group">
                <input
                 
                  type="date"
                  autoComplete="off"
                  placeholder=""
                  className="form-control"
                   name="date"
                  value={from_date}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="input-group">
                <input
                 
                  type="date"
                  autoComplete="off"
                   name="date"
                  value={to_date}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder=""
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 btn-box">
              <button
                onClick={() => fetchData(from_date, to_date)}
                className="btn btn-warning"
              >
                Load Report
              </button>
            </div>
          </form>
          <div className="table-responsive">
            <table className="table table-bordered w-100">
              <thead>
                <tr>
                  <th>Match</th>
                  <th width="160px">Stack</th>
                  <th width="150px">Profit/Loss</th>
                  <th width="150px">Commission</th>
                </tr>
              </thead>
              {Data &&
                Data.map((item, index) => (
                  <tbody>
                    <tr>
                      <td className="text-nowrap">
                        <span>
                          {item.sportName} | {item.seriesName}
                        </span>

                        <span className="d-block">{item.matchName}</span>
                        <span className="d-block">
                          {new Date(item.matchDate * 1000).toLocaleString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </span>
                      </td>
                      <td>{item.stack}</td>
                      <td>{item.userPL}</td>
                      <td>{item.userComm}</td>
                    </tr>
                  </tbody>
                ))}
              <tbody>
                {/* <!-- if data is not --> */}
                {/* <tr className="data-not-found">
                <td colspan="4" className="text-center">Not Found</td>
              </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default ProfitLossPage;
