import axios from "axios";
import { ChevronLeft, Eye, EyeOff } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ChangePassword() {
  const { token } = useSelector((store) => store.AuthReducer);
  const [Data, setData] = useState();
  const [formData, setFormData] = useState({
    confirmNewPassword: "",
    newPassword: "",
    oldPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior

    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/update-info",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attaches the authorization token to the request headers
          },
        }
      );
      setData(data.data); // Assuming setData is a function that updates some state with the response data
      toast[data.data.error ? "error" : "success"](data.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error fetching data:", error); // Logs any errors that occur during the API call
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // toggle eye button for show and hide passowrd
  const [showPassword, setShowPassword] = useState(false);
  const togglePass = () => {
    setShowPassword(!showPassword);
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const togglePass2 = () => {
    setShowPassword2(!showPassword2);
  };
  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>Change Password</h2>
            <Link to="/" className="back-link">
              Back <ChevronLeft />
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <form className="change-password">
                <div className="form-group mb-3">
                  <label className="small" htmlFor="oldPassword">
                    Current Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Current Password"
                    aria-describedby="basic-addon1"
                    name="oldPassword"
                    value={formData.oldPassword}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn eye"
                    onClick={togglePass}
                  >
                    {showPassword ? (
                      <EyeOff color="#fff" strokeWidth={1} />
                    ) : (
                      <Eye color="#fff" strokeWidth={1} />
                    )}
                  </button>
                </div>
                <div className="form-group mb-3">
                  <label className="small" htmlFor="newPassword">
                    New Password
                  </label>
                  <input
                    type={showPassword2 ? "text" : "password"}
                    className="form-control"
                    placeholder="New Password"
                    aria-describedby="basic-addon1"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="btn eye"
                    onClick={togglePass2}
                  >
                    {showPassword2 ? (
                      <EyeOff color="#fff" strokeWidth={1} />
                    ) : (
                      <Eye color="#fff" strokeWidth={1} />
                    )}
                  </button>
                </div>
                <div className="form-group mb-3">
                  <label className="small" htmlFor="confirmNewPassword">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm password"
                    aria-describedby="basic-addon1"
                    name="confirmNewPassword"
                    value={formData.confirmNewPassword}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group login-btn">
                  <button
                    className="btn btn-primary w-100"
                    onClick={handleSubmit}
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
