import React from 'react'
import whatsappIcon from "../../Assets/Images/Icons/Whatsapp.svg";
import phoneIcon from "../../Assets/Images/Icons/phone.png";
import { Link } from 'react-router-dom';

const ContactSocial = props => {
    return (
        <div className="payment-info social">
            <div className="social-icons contact-social">
              <ul>
                <li>
                  <Link to="https://wa.me/447491325779">
                    <img src={whatsappIcon} alt="Whatsapp Icon" />
                    <p>
                      Whatsapp <span>24/7</span>
                    </p>
                  </Link>
                </li>
                <li>
                  <Link to="tel:+44 7491325779">
                    <img src={phoneIcon} alt="Phone Icon" />
                    <p>
                      Phone <span>+44 7491 325779</span>
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
    )
}

export default ContactSocial
