import { ChevronLeft, Eye, EyeOff, X } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userAuthentication } from "../Redux/Auth/action";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SignIn() {
  const dispatch = useDispatch();
  const Nav = useNavigate();
  const [formData, setFormData] = useState({
    user_name: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
    dispatch(userAuthentication(formData))
      .then((res) => {
        if (res.type === "USER_LOGIN_SUCCESS") {
          toast.success("Login Succesfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          Nav("/");
        } else if (res.type === "USER_LOGIN_FAILURE") {
          // Assuming the failure type is "USER_LOGIN_FAILURE"
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // toggle eye button for show and hide passowrd
  const [showPassword, setShowPassword] = useState(false);
  const togglePass = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="fade offcanvas-backdrop sign show"></div>
      <div
        role="dialog"
        aria-modal="true"
        className="steps-canvas offcanvas offcanvas-end show"
        tabIndex={-1}
      >
        <div className="offcanvas-header justify-content-between">
          <div className="offcanvas-title h5">
            <span className="top-heading">Log In now!</span>
          </div>
          <Link className="text-white" to="/">
            <X />
          </Link>
        </div>
        <div className="offcanvas-body">
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <form className="" onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  name="user_name"
                  placeholder="Enter your username"
                  value={formData.user_name}
                  onChange={handleChange}
                  type="text"
                  id="formBasicName"
                  className="form-control"
                />
              </div>
              <div className="mb-3 position-relative">
                <input
                  name="password"
                  placeholder="Enter your password"
                  value={formData.password}
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  id="formBasicPassword"
                  className="form-control"
                />

                <span className="icon" onClick={togglePass}>
                  {showPassword ? (
                    <EyeOff color="#fff" strokeWidth={1} />
                  ) : (
                    <Eye color="#fff" strokeWidth={1} />
                  )}
                </span>
              </div>
              <div className="d-flex LoginBtn">
                <button type="submit" className="btn btn-primary">
                  Log In
                </button>
              </div>
            </form>

            <div className="sign-up mt-4">
              Not a member?
              <br />
              <Link to="/sign-up">Register Now</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
