import axios from "axios";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bank from "../Assets/Images/bank-transfer.svg";
import gpay from "../Assets/Images/gpay.svg";
import ppay from "../Assets/Images/phonepe.svg";
function WithdrawPage() {
  const { token } = useSelector((store) => store.AuthReducer);

  const [formData, setFormData] = useState({
    dwtype: "Normal",
    account_name: "",
    bankname: "",
    amount: "",
    attachment: "",
    account_number: "",
    ifsc: "",
    method: "IMPS",
    reciver: "",
    sender: "",
    transaction_id: "",
    type: "W",
    user_id: 0,
  });

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/my-deposit-request",
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast[data.data.error ? "error" : "success"](data.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <>
      <main className="main">
        <div className="container withdraw">
          <div className="PagesHeading">
            <h2>Withdraw</h2>
            <Link to="/" className="back-link">
              Back <ChevronLeft />
            </Link>
          </div>
          <div className="row">
            <div className="col-md-5 order-md-1 order-2 mt-md-0 mt-3">
              <ul className="">
                <li>
                  <span className="material-symbols-outlined"> east </span>
                  The bonus amount can be used to place bets across the platform
                  and the winnings can be withdrawn.
                </li>
                <li>
                  <span className="material-symbols-outlined"> east </span>A
                  player can use bonus amount to place bets and play games on
                  Kheloyar.
                </li>
                <li>
                  <span className="material-symbols-outlined"> east </span>
                  If the withdrawals are pending from the bank, it may take upto
                  72 banking hours for your transaction to clear.
                </li>
                <li className="mb-0">
                  <span className="material-symbols-outlined"> east </span>
                  If a user only deposits and attempts to withdraw the money
                  without placing a single bet, 100% of the amount will be
                  withheld due to suspicious activity. If this is repeated, no
                  withdrawal will be given to the user.
                </li>
              </ul>
            </div>
            <div className="col-md-7 order-md-2 order-1">
              <ul
                class="nav nav-pills gap-4 mb-2 justify-content-center p-3 bank-types"
                id="pills-tab"
                role="tablist"
              >
                <li class="nav-item mb-0" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-banktransfer-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-banktransfer"
                    type="button"
                    role="tab"
                    aria-controls="pills-banktransfer"
                    aria-selected="true"
                  >
                    <img src={bank} alt="" />
                  </button>
                </li>
                {/* <li class="nav-item mb-0" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-gpay-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-gpay"
                    type="button"
                    role="tab"
                    aria-controls="pills-gpay"
                    aria-selected="false"
                  >
                    <img src={gpay} alt="" />
                  </button>
                </li>
                <li class="nav-item mb-0" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-phonepay-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-phonepay"
                    type="button"
                    role="tab"
                    aria-controls="pills-phonepay"
                    aria-selected="false"
                  >
                    <img src={ppay} alt="" />
                  </button>
                </li> */}
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-banktransfer"
                  role="tabpanel"
                  aria-labelledby="pills-banktransfer-tab"
                  tabindex="0"
                >
                  <form className="withdraw-form" onSubmit={handleSubmit}>
                    {/* <label htmlFor="PaymentOption">Payment Options</label>
                  <div className="input-group mb-3">
                    <select
                      id="PaymentOption"
                      className="form-control form-select"
                    >
                      <option value="IMPS">Bank</option>
                      <option value="GP">GPay</option>
                      <option value="PP">Phonepe</option>
                    </select>
                  </div> */}
                    <label htmlFor="holdername">Holder Name</label>
                    <div className="input-group mb-3">
                      <input
                        id="holdername"
                        type="text"
                        className="form-control"
                        name="account_name"
                        value={formData.account_name}
                        onChange={handleInputChange}
                        placeholder="Holder Name"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-8">
                        <label htmlFor="bankname">Bank Name or G-pay or UPI Name</label>
                        <div className="input-group mb-3">
                          <input
                            id="bankname"
                            type="text"
                            className="form-control"
                            maxlength="{20}"
                            name="bankname"
                            value={formData.bankname}
                            onChange={handleInputChange}
                            placeholder="Bank Name"
                          />
                        </div>
                      </div>
                    </div>
                    <label htmlFor="accountNo">Account No Or UPI Number</label>
                    <div className="input-group mb-3">
                      <input
                        id="accountNo"
                        type="number"
                        className="form-control"
                        name="account_number"
                        value={formData.account_number}
                        onChange={handleInputChange}
                        placeholder="Account No"
                      />
                    </div>
                    <label htmlFor="ibnifsccode">IBAN/IFSC Number</label>
                    <div className="input-group mb-3">
                      <input
                        id="ibnifsccode"
                        type="text"
                        className="form-control"
                        name="ifsc"
                        value={formData.ifsc}
                        onChange={handleInputChange}
                        placeholder="IBAN/IFSC Number"
                      />
                    </div>
                    <label htmlFor="accountNo">Account Type</label>
                    <div className="input-group mb-3">
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="account_type"
                          id="savings"
                          value="option1"
                          checked
                        />
                        <label className="form-check-label" htmlFor="savings">
                          Savings
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="account_type"
                          id="current"
                          value="option2"
                        />
                        <label className="form-check-label" htmlFor="current">
                          Current
                        </label>
                      </div>
                    </div>
                    <label htmlFor="accountNo">Request Method</label>
                    <div className="input-group mb-3">
                      <div className="form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="dwtype"
                          id="dwtype"
                          value="normal"
                          checked={formData.dwtype === "normal"}
                          onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="normal">
                          Normal
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="dwtype"
                          id="dwtype"
                          value="instant"
                          checked={formData.dwtype === "instant"}
                          onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="Fast">
                          Fast
                        </label>
                      </div>
                    </div>
                    <label htmlFor="accountNo">Amount</label>
                    <div className="input-group mb-3">
                      <input
                        id="accountNo"
                        type="number"
                        className="form-control"
                        name="amount"
                        value={formData.amount}
                        onChange={handleInputChange}
                        placeholder="Amount"
                      />
                    </div>
                    <div className="add-account-btn mt-2">
                      <div className="d-flex m-auto w-md-50">
                        <button
                          type="submit"
                          className="btn btn btn-primary py-2 w-100"
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-gpay"
                  role="tabpanel"
                  aria-labelledby="pills-gpay-tab"
                  tabindex="0"
                >
                  <form className="withdraw-form">
                    <label htmlFor="holdername">Phone No./UPI</label>
                    <div className="input-group mb-3">
                      <input
                        id="upi_number"
                        type="text"
                        className="form-control"
                        name="upi_number"
                        placeholder="Phone No./UPI"
                      />
                    </div>
                    <label htmlFor="a_name">Account Name</label>
                    <div className="input-group mb-3">
                      <input
                        id="a_name"
                        type="text"
                        className="form-control"
                        maxlength="{20}"
                        name="a_name"
                        placeholder="Account Name"
                      />
                    </div>
                    <label htmlFor="accountNo">Amount</label>
                    <div className="input-group mb-3">
                      <input
                        id="accountNo"
                        type="number"
                        className="form-control"
                        name="amount"
                        placeholder="Amount"
                      />
                    </div>
                    <div className="add-account-btn mt-2">
                      <div className="d-flex m-auto w-md-50">
                        <button
                          type="submit"
                          className="btn btn btn-primary py-2 w-100"
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-phonepay"
                  role="tabpanel"
                  aria-labelledby="pills-phonepay-tab"
                  tabindex="0"
                >
                  <form className="withdraw-form">
                    <label htmlFor="holdername">Phone No./UPI</label>
                    <div className="input-group mb-3">
                      <input
                        id="upi_number"
                        type="text"
                        className="form-control"
                        name="upi_number"
                        placeholder="Phone No./UPI"
                      />
                    </div>
                    <label htmlFor="a_name">Account Name</label>
                    <div className="input-group mb-3">
                      <input
                        id="a_name"
                        type="text"
                        className="form-control"
                        maxlength="{20}"
                        name="a_name"
                        placeholder="Account Name"
                      />
                    </div>
                    <label htmlFor="accountNo">Amount</label>
                    <div className="input-group mb-3">
                      <input
                        id="accountNo"
                        type="number"
                        className="form-control"
                        name="amount"
                        placeholder="Amount"
                      />
                    </div>
                    <div className="add-account-btn mt-2">
                      <div className="d-flex m-auto w-md-50">
                        <button
                          type="submit"
                          className="btn btn btn-primary py-2 w-100"
                        >
                          Withdraw
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default WithdrawPage;
