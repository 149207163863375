import { Link, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
function MatkaDetails() {
  const params = useParams(); 

  const [Data, setData] = useState([]);
  const [Data1, setData1] = useState([]);
  const [Data2, setData2] = useState();
  const [Market, setMarket] = useState();
  const [Market1, setMarket1] = useState();
  const { token } = useSelector((store) => store.AuthReducer);
  const [inputValues, setInputValues] = useState([]);
  const [inputValues1, setInputValues1] = useState([]);
  const [inputValues2, setInputValues2] = useState([]);
  const [details, setDetails] = useState([]);

// console.log(params);
const {match_id,sport_id}=params


  const fetchData = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/event-money",
        { match_id,sport_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
// console.log(data)
      setData(data.data.data.MatchDetails.close_market.runner_json);
      setData1(data.data.data.MatchDetails.open_market.runner_json);
      setData2(data.data.data.MatchDetails.jodi_market.runner_json);
      setMarket(data.data.data.MatchDetails.close_market.marketId)
      setMarket1(data.data.data.MatchDetails.jodi_market.marketId)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  
  const handleInputChange = (value, selectionId,marketId) => {
    const newInputValues = [...inputValues];
    newInputValues[selectionId] = { market_id:Market, selection_id:selectionId, amount: value };
    setInputValues(newInputValues);
  };

  const handleInputChange1 = (index, value, selectionId,marketId) => {
   
    let newInputValues = [...inputValues1];
    newInputValues[index] = value? { market_id:Market, selection_id:selectionId, amount: value }:null;
    !value && newInputValues.splice(index,1)
    
    setInputValues1(newInputValues);

  };

  const filter1 = (arr) => {
    const filteredValues = arr.filter((item) => item !== undefined);
    return filteredValues;

  }

  let filteredValues=  filter1([...inputValues, ...inputValues1]);


  // const filter2 = (arr) => {
  //   const filteredValues1 = arr.filter((item) => item !== undefined);
  //   return filteredValues1;

  // }
  // console.log(Data2[9]);
  // console.log(Data2[0]?.back[0]?.price)

  let filteredValues1= filter1([...inputValues2]);
  // console.log({filteredValues1})


  const handleInputChange2 = (index,value,selectionId, Market1) => {
    let newInputValues = [...inputValues2];
    newInputValues[index] = value? { market_id:Market1, selection_id:selectionId, amount: value }:null;
    !value && newInputValues.splice(index,1)
    
    setInputValues2(newInputValues);

  };


  // console.log({filteredValues})
  // console.log(Market)


  const handleBet = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/save-mtk-tmp-bet",
        { match_id:params?.match_id,bet_data:filteredValues},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        
      );
      toast[data.data.error ? 'error' : 'success'](data.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      BetDetails()
      // window.location.reload()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  
  const handleBet1 = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/save-mtk-tmp-bet",
        { bet_data:filteredValues1,match_id:params?.match_id},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast[data.data.error ? 'error' : 'success'](data.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      BetDetails()
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 

  const BetDetails = async () => {
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/get-mtk-temp-bet",
        {match_id:params?.match_id},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDetails(data.data.data)
      console.log("data",data.data.data)
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 
  useEffect(() => {
    fetchData();
    BetDetails()
  }, []);



  return (
    <>
      <section className="wrapper matka-details pt-5">
        <div className="report-box casino-box pt-5" >
         
              <div className="main-container pt-5">
               
                <div className="center-main-content">
                  <div className="w-100 sport-tabs h-auto p-3">
                    <ul
                      className="nav nav-tabs border border-secondary mb-3 h-auto"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link flex-column h-auto py-2 lh-base active"
                          id="pills-haroof-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-haroof"
                          type="button"
                          role="tab"
                          aria-controls="pills-haroof"
                          aria-selected="true"
                        >
                          <strong>Haroof</strong>
                          <span className="d-block">Rate : { Data && Data[0]?.back[0]?.price}</span>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link flex-column h-auto py-2 lh-base"
                          id="pills-jodiDigit-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-jodiDigit"
                          type="button"
                          role="tab"
                          aria-controls="pills-jodiDigit"
                          aria-selected="false"
                        >
                          <strong>Jodi Digits</strong>
                          <span className="d-block">Rate :{Data2&& Data2[0]?.back[0]?.price}</span>
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-haroof"
                        role="tabpanel"
                        aria-labelledby="pills-haroof-tab"
                        tabindex="0"
                      >
                       <div>
                <div className="pb-3 pt-1">
                  <p className="text-white">Min Bet Amount:</p>
                  <p className="text-white">Max Bet Amount:</p>
                </div>

                <div className="row">
                  {Data && Data.map((item, index) => (
                    <div className="col-3 col-md-1  mb-1">
                      <div className="form-group">
                        <label htmlFor="" className="text-white">
                          {item.SelectionName}
                        </label>
                        <input
                          type="number"
                          name=""
                          className="form-control"
                          value={inputValues1.value} // Set input value from state
                          onChange={(e) => handleInputChange1(index, e.target.value, item.selectionId ,Market1.marketId)} // Update state on input change
                        />
                      </div>
                    </div>
                  ))}
                </div>


                <div className="row">
                  {/* open market  */}
                  {Data1 && Data1.map((item, index) => (
                    <div className="col-3 col-md-1  mb-1">
                      <div className="form-group">
                        <label htmlFor="" className="text-white">
                          {item.SelectionName}
                        </label>
                        <input
                          type="number"
                          name=""
                          className="form-control"
                          value={inputValues.value} // Set input value from state
                          onChange={(e) => handleInputChange(e.target.value, item.selectionId,Market)} // Update state on input change
                        />
                      </div>
                    </div>
                  ))}
                </div>


                <div className="row justify-content-between align-items-center my-4">
                  <div className="col-md-2 col-5 pe-0">
                    {/* <p className="text-white">Total Amount</p>
                    <div className="bg-light w-100 p-2">
                      <h5 className="mb-0 text-center">111</h5>
                    </div> */}
                  </div>
                  <div className="col-md-5 col-7 d-flex justify-content-end mt-md-0 mt-4">
                  <button className="btn btn-primary me-3" onClick={handleBet}>Add Bet</button>

                    <button className="btn btn-danger">Clear Bet</button>
                  </div>
                </div>
              </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="pills-jodiDigit"
                        role="tabpanel"
                        aria-labelledby="pills-jodiDigit-tab"
                        tabindex="0"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="pills-haroof"
                          role="tabpanel"
                          aria-labelledby="pills-haroof-tab"
                          tabindex="0"
                        >
                         <div>
                  <div className="pb-3 pt-1 d-flex flex-md-row flex-column justify-content-between align-items-start">
                    <div>
                      <p className="text-white">Min Bet Amount: </p>
                      <p className="text-white">Max Bet Amount: </p>
                    </div>
                    <div className="d-flex gap-2 mt-md-0 mt-3">
                      {/* <button className="btn btn-warning">Number</button>
                      <button className="btn btn-success">Range</button>
                      <button className="btn btn-danger">Crossing</button> */}
                    </div>
                  </div>
                  <div className="row">
                 
                    {Data2 && Data2.map((item, index) => (
                    <div className="col-md-1 col-3 mb-1">
                      <div className="form-group">
                        <label htmlFor="" className="text-white">
                          {item.SelectionName}
                        </label>
                        <input
                          type="number"
                          name=""
                          className="form-control text-white"
                          value={inputValues2.value} // Set input value from state
                          onChange={(e) => handleInputChange2(index,e.target.value, item.selectionId,Market1)} // Update state on input change
                        />
                      </div>
                    </div>
                  ))}
                
                   
                   
                   
                  </div>
                  <div className="row justify-content-between align-items-center my-4">
                    <div className="col-md-2 col-5 pe-0">
                      {/* <p className="text-white">Total Amount</p>
                      <div className="bg-light w-100 p-2">
                        <h5 className="mb-0 text-center">111</h5>
                      </div> */}
                    </div>
                    <div className="col-md-5 col-7 d-flex justify-content-end mt-md-0 mt-4">
                    <button className="btn btn-primary me-3" onClick={handleBet1}>Add Bet</button>
                      <button className="btn btn-danger">Clear Bet</button>
                    </div>
                  </div>
                </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
        </div>






        {details && details.length === 0 ? (
           <div className="betslip-body">
         
          </div>
            ) : (
          <div className="table-responsive px-3">
            <table className="table statement-table table-bordered w-100">
              <thead>
                <tr >
                  <th className="text-black">S-NO</th>
                  <th className="text-black" width="160px">Market</th>
                  <th className="text-black" width="160px">Selection Name</th>
                  <th className="text-black" width="160px">Amount</th>

                </tr>
              </thead>
              <tbody >
                {details &&
                  details.map((item, index) => (
                    <tr>
                      <td className="text-">
                      
                        <span className="d-block text-black">{
                          index+1
                        }</span>
                        <span className="d-block text-black">

                        {item.patti_type}
                        </span>
                      </td>
                      <td className="text-black">

                        {item.market_name}
                        <span className="d-flex justify-content-end fw-bold">
                          {item.Type}
                        </span>
                      </td>
                      <td className="text-black">{item.selectionName}</td>
                      <td className="text-black">{item.stack}</td>


                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
            )}










      </section>
    </>
  );
}

export default MatkaDetails;
