import axios from "axios";
import { data } from "jquery";
import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import bank from "../Assets/Images/bank-transfer.svg";
import gpay from "../Assets/Images/upi.webp";
function DepositPage() {
  const { token } = useSelector((store) => (store.AuthReducer))
  const [Data, setData] = useState()
  const [Data1, setData1] = useState()
  const [Data2, setData2] = useState()
  const [formData, setFormData] = useState({
    amount: "",
    transaction_id: "",
    dwtype: "D",
    account_name: "",
    bankname: "",
    attachment: "",
    account_number: "",
    ifsc: "",
    method: "IMPS",
    reciver: "",
    sender: "",
    type: "D",
    user_id: 4498
  });



  const handleFileChange = async (event) => {
    const formdata = new FormData();
    formdata.append('deposite', event.target.files[0]);
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/uploadDepositeFile",
        formdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const attachment = data.data.data.attachment;

      setData2(attachment);
      setFormData((prevFormData) => ({
        ...prevFormData,

        attachment: attachment
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
      const data = await axios.post(
        "https://vbet002.com/api/v5/my-deposit-request",
        { ...formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast[data.data.error ? "error" : "success"](data.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to update the form data when an amount is selected
  const handleAmountSelect = (amount) => {
    setFormData({
      ...formData,
      amount
    });
  };
  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://vbet002.com/api/v5/customer-details",
        { type: "D" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response)
      const customerData = response.data.data;
      const customerData1 = response.data.data[0];

      setData(customerData);
      setData1(customerData1);
      setFormData((prevFormData) => ({
        ...prevFormData,
        account_name: customerData1.account_holder_name,
        bankname: customerData1.bank_name,
        account_number: customerData1.account_phone_number,
        ifsc: customerData1.account_ifsc_code
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();

  }, []);


  return (
    <>
      <main className="main">
        <div className="container withdraw">
          <div className="PagesHeading">
            <h2>Deposit</h2>
          </div>
          <ul
            class="nav nav-pills gap-4 mb-3 justify-content-center p-3 bank-types"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item mb-0" role="presentation">
              <button
                class="nav-link active"
                id="pills-banktransfer-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-banktransfer"
                type="button"
                role="tab"
                aria-controls="pills-banktransfer"
                aria-selected="true"
              >
                <img src={bank} alt="" />
              </button>
            </li>
            <li class="nav-item mb-0" role="presentation">
              <button
                class="nav-link"
                id="pills-gpay-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-gpay"
                type="button"
                role="tab"
                aria-controls="pills-gpay"
                aria-selected="false"
              >
                <img src={gpay} alt="" />
              </button>
            </li>

          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-banktransfer"
              role="tabpanel"
              aria-labelledby="pills-banktransfer-tab"
              tabindex="0"
            >
              <div className="row">

                <div className="col-md-6 order-md-1 order-2 mt-md-0 mt-4">
                  {Data && Data.slice(0, 1).map((item, index) => (
                    <div className="account-details" key={index}>
                      <div className="sectionList">
                        <p className="fw-medium">Account Number:</p>
                        <div>
                          <p>{item.account_phone_number}</p>
                          <span className="material-symbols-outlined">
                            content_copy
                          </span>
                        </div>
                      </div>
                      <div className="sectionList">
                        <p className="fw-medium">Account Holder Name:</p>
                        <div>
                          <p>{item.
                            account_holder_name
                          }</p>
                          <span className="material-symbols-outlined">
                            content_copy
                          </span>
                        </div>
                      </div>
                      <div className="sectionList">
                        <p className="fw-medium">Bank Name:</p>
                        <div>
                          <p>{item.bank_name}</p>
                          <span className="material-symbols-outlined">
                            content_copy
                          </span>
                        </div>
                      </div>
                      <div className="sectionList">
                        <p className="fw-medium">IFSC Code:</p>
                        <div>
                          <p>{item.account_ifsc_code}</p>
                          <span className="material-symbols-outlined">
                            content_copy
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  <ul>
                    <li className="mb-0">
                      <h5 className="text-danger mb-3">Please Note</h5>
                    </li>
                    <li>
                      <span className="material-symbols-outlined"> east </span>
                      Deposit money only in the above available account to get the
                      fastest credit & avoid possible delays.
                    </li>
                    <li>
                      <span className="material-symbols-outlined"> east </span>
                      After Deposit, upload your deposit slip screenshot to receive
                      balance.
                    </li>
                    <li>
                      <span className="material-symbols-outlined"> east </span>
                      Requested amount should be the same as deposit slip amount for
                      smooth deposit process.
                    </li>
                    <li className="mb-0">
                      <span className="material-symbols-outlined"> east </span>
                      NEFT receiving time is from 40 minutes to 2 hours.
                    </li>
                  </ul>
                </div>


                <div className="col-md-6 order-md-2 order-1">
                  <form className="deposit-form" onSubmit={handleSubmit}>
                    {/* <label htmlFor="file_upload">Upload File</label>
                    <div className="input-group mb-3">
                      <input
                        id="file_upload"
                        type="file"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                    </div> */}
                    {/* {file && <p>Selected file: {file.name}</p>} */}
                    <label htmlFor="amount">Amount</label>
                    <div className="input-group mb-3">
                      <input
                        id="amount"
                        type="text"
                        className="form-control"
                        value={formData.amount}
                        onChange={(e) => setFormData({ ...formData, amount: Number(e.target.value) })}
                        placeholder="Amount"
                      />
                    </div>
                    <div className="qa_wrap">
                      {[500, 1000, 2000, 5000, 10000, 20000, 25000, 50000, 100000].map((amount, index) => (
                        <div
                          className="btn btn-info"
                          key={index}
                          onClick={() => handleAmountSelect(amount)}
                        >
                          {`+${amount}`}
                        </div>
                      ))}
                    </div>

                    <label htmlFor="amount">Unique Transaction Reference</label>
                    <div className="input-group mb-3">
                      <input
                        id="amount"
                        type="text"
                        className="form-control"
                        value={formData.transaction_id}
                        onChange={(e) => setFormData({ ...formData, transaction_id: e.target.value })}
                        placeholder="6 to 12 Digits UTR Number"
                        maxLength={20}
                      />
                    </div>
                    <div className="add-account-btn mt-2">
                      <button type="submit" className="btn btn-primary py-2 w-100">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-gpay"
              role="tabpanel"
              aria-labelledby="pills-gpay-tab"
              tabindex="0"
            >
              <div className="row justify-content-center">
                {Data && Data.map((item, index) => (<div className="col-lg-3 col-md-3 mb-3">
                  <img className="img-fluid" src={`https://admin.vbet002.com/storage/app/public/payment/${item.attachment}`} />
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default DepositPage;
